import { getCordataWemTriples } from "@biblioteksentralen/bmdb-search-client";
import { Work } from "@biblioteksentralen/cordata";
import { normalizeIsbn } from "@libry-content/common";
import { sort } from "radash";
import { getAvailableWorkLanguages } from "./languages";
import { getDocumentTypes, getFormatOrderIndex } from "./manifestations";

export class CordataWork {
  private work: Work;

  constructor(work: Work) {
    this.work = work;
  }

  public getWorkTriple(identifier: WorkTripleIdentifier) {
    return getCordataWemTriples(this.work).find(({ manifestation }) => {
      if ("manifestationId" in identifier) return manifestation.id === identifier.manifestationId;

      return manifestation.isbn.some((isbn) => normalizeIsbn(isbn) === normalizeIsbn(identifier.isbn));
    });
  }

  public getWorkTriples() {
    return getCordataWemTriples(this.work);
  }

  public getAvailableWorkLanguages() {
    return getAvailableWorkLanguages(this.work);
  }

  public getDocumentTypes() {
    return sort(getDocumentTypes(this.work), (documentType) => getFormatOrderIndex(documentType?.format));
  }
}

export type WorkTripleIdentifier = { manifestationId: string } | { isbn: string };
