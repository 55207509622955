import { Work } from "@biblioteksentralen/cordata";
import { isNonNil } from "@libry-content/common";
import { LocalizedField, TranslateSanityContent } from "@libry-content/localization";
import { unique } from "radash";

/**
 * Takes genre names in bokmål as they are specified by Nasjonalbiblioteket
 * through Cordata and applies the following transformations:
 * - To singular
 * - Add nynorsk translation
 * - Remove format specifiers that are unnecessary in context, e.g. "Fantasy (film)"
 */
const genreNameTransformations: Record<string, LocalizedField<string>> = {
  // Bøker
  Antologier: { nb: "Antologi", nn: "Antologi" },
  Bildebøker: { nb: "Bildebok", nn: "Biletbok" },
  Bilderomaner: { nb: "Bilderoman", nn: "Biletroman" },
  Bollywood: { nb: "Bollywood", nn: "Bollywood" },
  Dikt: { nb: "Dikt", nn: "Dikt" },
  Diktromaner: { nb: "Diktroman", nn: "Diktroman" },
  Dokudrama: { nb: "Dokudrama", nn: "Dokudrama" },
  Fortellinger: { nb: "Fortelling", nn: "Forteljing" },
  "Grafiske romaner": { nb: "Grafisk roman", nn: "Grafisk roman" },
  Kortprosa: { nb: "Kortprosa", nn: "Kortprosa" },
  Kortromaner: { nb: "Kortroman", nn: "Kortroman" },
  Lærebøker: { nb: "Lærebok", nn: "Lærebok" },
  Noveller: { nb: "Novelle", nn: "Novelle" },
  Pekebøker: { nb: "Pekebok", nn: "Peikebok" },
  Romaner: { nb: "Roman", nn: "Roman" },
  Skuespill: { nb: "Skuespill", nn: "Skodespel" },
  Serieromaner: { nb: "Serieroman", nn: "Serieroman" },
  Tegneserier: { nb: "Tegneserie", nn: "Teikneserie" },
  // Film (https://www.nb.no/nbvok/ntsf/nb/page/?uri=https%3A%2F%2Fid.nb.no%2Fvocabulary%2Fntsf%2F1122)
  Action: { nb: "Action", nn: "Action" },
  "Adaptasjoner (film)": { nb: "Adaptasjon", nn: "Adaptasjon" },
  Animasjonsfilmer: { nb: "Animasjon", nn: "Animasjon" },
  Anime: { nb: "Anime", nn: "Anime" },
  "Biografiske filmer": { nb: "Biografisk", nn: "Biografisk" },
  Dansefilmer: { nb: "Dansefilm", nn: "Dansefilm" },
  Dokumentarfilmer: { nb: "Dokumentar", nn: "Dokumentar" },
  "Drama (film)": { nb: "Drama", nn: "Drama" },
  "Dystopiske filmer": { nb: "Dystopisk", nn: "Dystopisk" },
  "Eksperimentelle filmer": { nb: "Eksperimentell film", nn: "Eksperimentell film" },
  "Erotiske filmer": { nb: "Erotisk", nn: "Erotisk" },
  Eventyrfilmer: { nb: "Eventyrfilm", nn: "Eventyrfilm" },
  Familiefilmer: { nb: "Familiefilm", nn: "Familiefilm" },
  "Fantasy (film)": { nb: "Fantasy", nn: "Fantasy" },
  "Film noir": { nb: "Film noir", nn: "Film noir" },
  "Grøssere (film)": { nb: "Grøsser", nn: "Grøssar" },
  "Historiske filmer": { nb: "Historisk", nn: "Historisk" },
  "Komedier (film)": { nb: "Komedie", nn: "Komedie" },
  Konsertfilmer: { nb: "Konsertfilm", nn: "Konsertfilm" },
  Kortfilmer: { nb: "Kortfilm", nn: "Kortfilm" },
  Krigsfilmer: { nb: "Krigsfilm", nn: "Krigsfilm" },
  "Krim (film)": { nb: "Krim", nn: "Krim" },
  "Mini-serier": { nb: "Mini-serie", nn: "Mini-serie" },
  Musikalfilmer: { nb: "Musikalfilm", nn: "Musikalfilm" },
  Musikkfilmer: { nb: "Musikkfilm", nn: "Musikkfilm" },
  Naturfilmer: { nb: "Naturfilm", nn: "Naturfilm" },
  Operafilmer: { nb: "Operafilm", nn: "Operafilm" },
  Opplysningsfilmer: { nb: "Opplysningsfilm", nn: "Opplysningsfilm" },
  "Oppvekst (film)": { nb: "Oppvekst", nn: "Oppvekst" },
  "Romantiske filmer": { nb: "Romantisk", nn: "Romantisk" },
  "Science fiction (film)": { nb: "Science fiction", nn: "Science fiction" },
  Serier: { nb: "Serie", nn: "Serie" },
  "Spenning (film)": { nb: "Spenning", nn: "Spenning" },
  Spillefilmer: { nb: "Spillefilm", nn: "Spelefilm" },
  Spionfilmer: { nb: "Spion", nn: "Spion" },
  Sportsfilmer: { nb: "Sport", nn: "Sport" },
  Stumfilmer: { nb: "Stumfilm", nn: "Stumfilm" },
  "Superhelter (film)": { nb: "Superhelter", nn: "Superheltar" },
  "Thrillere (film)": { nb: "Thriller", nn: "Thriller" },
  "TV-filmer": { nb: "TV-film", nn: "TV-film" },
  "Western (film)": { nb: "Western", nn: "Western" },
};

const transformGenreName =
  (ts: TranslateSanityContent) =>
  ({ name }: { name: LocalizedField<string> }) =>
    ts(genreNameTransformations[name.nb ?? ts(name) ?? ""]) ?? ts(name);

export const getGenres = (ts: TranslateSanityContent, { genres }: Work, { isForm } = { isForm: false }) =>
  unique(genres.filter((genre) => !!genre.isForm === !!isForm).map(transformGenreName(ts))).filter(isNonNil);
