import { Work, formatIsbdTitle } from "@biblioteksentralen/cordata";
import { HStack, Heading, Text, VStack } from "@biblioteksentralen/react";
import { formatLanguagesList } from "../../cordata/languages";
import { DocumentTypes } from "../DocumentTypes";
import { WorkCoverImage } from "../WorkCoverImage";
import { getDotseparated } from "../getDotseparated";
import { ReservableHolding } from "./useChosenReservableHolding";

type ReservationModalWorkPreviewProps = {
  work: Work;
  reservableHolding: ReservableHolding;
  hideImage?: boolean;
};

export const ReservationModalWorkPreview = ({
  work,
  hideImage,
  reservableHolding: { manifestation },
}: ReservationModalWorkPreviewProps) => {
  const languagesAndYear = [formatLanguagesList(manifestation.expression.languages), manifestation.publicationYear];

  return (
    <HStack as="section" spacing="1rem">
      <WorkCoverImage
        work={work}
        representativeManifestation={manifestation}
        usePlaceholder={hideImage}
        maxWidth="7rem"
      />
      <VStack alignItems="flex-start" spacing="0.75rem">
        {!!manifestation.documentType && (
          <DocumentTypes documentTypes={[manifestation.documentType]} genres={work.genres} lineHeight="1" />
        )}
        <Heading as="h2" fontSize="lg" /* TODO */>
          {formatIsbdTitle(manifestation)}
        </Heading>
        <Text fontSize="sm">{getDotseparated(languagesAndYear)}</Text>
      </VStack>
    </HStack>
  );
};
