import useSWR from "swr";
import { swrUserNamespace, useRediaPlatformContext } from "../../../rediaPlatform/RediaPlatformProvider";
import { PatronLoan } from "@libry-content/redia-platform";
import { tryFetchPublications } from "./tryFetchPublications";
import { MinsidePublication, minsidePublicationMap } from "./minsidePublicationMap";

export interface LoanWithPublicationMetadata {
  loan: PatronLoan;
  publication: MinsidePublication;
}

export const useLoans = () => {
  const { user, rediaPlatform } = useRediaPlatformContext();
  const swrCacheKey = user && rediaPlatform ? [swrUserNamespace, user?.identifiers?.[0]?.value, "loans"] : null;

  return useSWR(swrCacheKey, async () => {
    if (!rediaPlatform) {
      throw new Error("Redia Platform should be initialized at this point.");
    }

    const loans: PatronLoan[] = (await rediaPlatform.getLoans()) ?? [];
    const publicationResponse = await tryFetchPublications(rediaPlatform, loans);

    return loans.map(
      (loan): LoanWithPublicationMetadata => ({
        loan,
        publication: minsidePublicationMap({
          id: loan.id,
          publicationId: loan.publicationId,
          publication: publicationResponse[loan.publicationId],
          patronPublicationItem: loan,
        }),
      })
    );
  });
};
