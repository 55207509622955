import { CordataSearchWorksResponse } from "@biblioteksentralen/bmdb-search-client";
import { Agent, Expression, Manifestation, Person } from "@biblioteksentralen/cordata";
import { FrontendLocale } from "@libry-content/localization";
import { ResolvedEvent } from "@libry-content/common";
import { ContributorRoleCode } from "./cordata/roles";
import { ArrayElement } from "@libry-content/types";

export type CordataSearchFacetTerm = ArrayElement<
  ArrayElement<NonNullable<CordataSearchWorksResponse["facets"]>>["terms"]
>;

export const isPerson = (agent: Pick<Agent, "type">): agent is Person => agent?.type === "Person";

export type SanityEventsResponse = { events: ResolvedEvent[] };

const searchFilters = ["book", "movie", "videogame", "agents", "content"] as const;

export type SearchFilter = (typeof searchFilters)[number];

export const isSearchFilter = (item: unknown): item is SearchFilter =>
  typeof item === "string" && (searchFilters as readonly string[]).includes(item);

type CommonSearchRequestSchema = { searchQuery?: string; size?: number; from?: number };

const workSearchFilters = ["book", "movie", "videogame"] as const satisfies Readonly<SearchFilter[]>;

export type WorkSearchFilter = (typeof workSearchFilters)[number];

export const isWorkSearchFilter = (item: unknown): item is WorkSearchFilter =>
  isSearchFilter(item) && (workSearchFilters as Readonly<SearchFilter[]>).includes(item);

export type SearchContentRequestData = CommonSearchRequestSchema & {
  siteDomain?: string;
  languageCode: FrontendLocale;
};

export type SearchAgentsRequestData = CommonSearchRequestSchema;

export type SearchWorksByContributorRoleRequestData = CommonSearchRequestSchema & {
  agentId: string;
  roleCode: ContributorRoleCode;
};

export type ExpressionSummary<T extends Expression> = Pick<
  T,
  "languages" | "collections" | "contributors" | "title" | "platforms" | "numberOfPlayers"
>;

export type SortableManifestation = Manifestation & {
  isAggregateManifestation?: boolean;
  expression: ExpressionSummary<Expression>;
};
