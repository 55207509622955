import { Button, Spinner } from "@biblioteksentralen/react";
import { PatronList } from "@libry-content/redia-platform";
import { Check } from "react-feather";
import { FavoriteListWithFavoriteItems, useFavoriteList } from "../../../components/minside/dataFetchers/useFavorites";
import { useSitePalette } from "../../../utils/useSitePalette";
import { isItemInFavoriteList } from "./FavoriteButton";

type Props = {
  publicationId?: string;
  workId?: string;
  handleAddItemToList: (id: string, data: FavoriteListWithFavoriteItems) => void;
  list: PatronList;
};

const FavoriteAddItemButton = ({ workId, list, handleAddItemToList }: Props) => {
  const data = useFavoriteList(list.id);
  const palette = useSitePalette();

  if (!data) return null;

  const { data: favorite, error } = data;

  if (error || !workId) return null;

  if (!favorite) return <Spinner />;

  const isInList = isItemInFavoriteList(workId, favorite.items);

  const color = isInList ? palette.colors.darkaccent2.css : palette.colors.card.css;

  return (
    <Button
      key={list.id}
      size="sm"
      variant="tertiary"
      borderRadius="1em"
      paddingX="0.75em !important"
      leftIcon={isInList ? <Check size="1em" strokeWidth={3} /> : undefined}
      onClick={() => handleAddItemToList(list.id, favorite)}
      whiteSpace="pre-wrap"
      textAlign="left"
      height="auto"
      _hover={{ textDecoration: "underline" }}
      {...color}
    >
      {list.name}
    </Button>
  );
};

export default FavoriteAddItemButton;
