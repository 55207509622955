import { Agent } from "@biblioteksentralen/cordata";
import { PickFromExtracted } from "@libry-content/types";
import { isPerson } from "../types";
import { TranslateSanityContent } from "@libry-content/localization";

// TODO: Is it correct to use just the first item?
export const getAgentTitle = (
  ts: TranslateSanityContent,
  agent: PickFromExtracted<Agent, { type: "Person" }, "type" | "personTitle", "type">
) => (isPerson(agent) ? ts(agent.personTitle)?.split(",")?.[0] : undefined);

export const formatAgentName = (
  ts: TranslateSanityContent,
  agent: PickFromExtracted<Agent, { type: "Person" }, "name" | "type" | "ordinal", "name" | "type">
) => `${ts(agent.name)}${isPerson(agent) && !!agent.ordinal ? ` ${agent.ordinal}` : ""}`;
