import { Work } from "@biblioteksentralen/cordata";
import { Box, BoxProps, Button, IconButton, useDisclosure } from "@biblioteksentralen/react";
import { ComponentProps, useRef } from "react";
import { Heart } from "react-feather";
import {
  FavoriteListWithFavoriteItems,
  useAllFavoriteLists,
} from "../../../components/minside/dataFetchers/useFavorites";
import { useTranslation } from "../../../utils/hooks/useTranslation";
import { FavoritePopover } from "./FavoritePopover";
import { isWork } from "../../cordata/works";
import { useLibrarySystemClient } from "../../../utils/hooks/useLibrarySystemClient";

type ReservationButtonProps = {
  workOrWorkId: Work | string;
} & ComponentProps<typeof Button>;

export const isItemInFavoriteList = (workId: string, items: FavoriteListWithFavoriteItems["items"]) =>
  items.map(({ rediaItem }) => rediaItem.workId).includes(workId);

const isItemInAFavoriteLists = (workId: string, lists: FavoriteListWithFavoriteItems[]) =>
  lists?.some(({ items }) => isItemInFavoriteList(workId, items));

export const FavoriteButton = ({ workOrWorkId, ...buttonProps }: ReservationButtonProps) => {
  const { isOpen, onToggle, onClose } = useDisclosure();
  const { data } = useAllFavoriteLists();
  const { t } = useTranslation();

  // Chakra skal egentlig sette fokus tilbake på knappen som åpnet modalen av seg selv
  // Dette funka vist ikke helt når bruker ikke var logget inn og man får innloggingskjemaet i en modal, men om vi sender med en finalFocusRef så funker det.
  // Vi trenger ikke å kalle fokus på finalFocusRef selv, det gjør chakra for oss.
  const onCloseFocusRef = useRef<HTMLDivElement>(null);

  const workId = isWork(workOrWorkId) ? workOrWorkId.id : workOrWorkId;
  const isFavorite = data?.lists && isItemInAFavoriteLists(workId, data.lists);

  return (
    <FavoritePopover
      popoverTrigger={
        <IconButton
          icon={<Heart fill={isFavorite ? "black" : "none"} />}
          aria-label={t("Legg til i liste")}
          ref={onCloseFocusRef}
          backgroundColor="whiteAlpha.700"
          boxShadow="lg"
          onClick={onToggle}
          variant="plain"
          borderRadius="50%"
          padding=".2rem"
          {...buttonProps}
        />
      }
      workOrWorkId={workOrWorkId}
      isOpen={isOpen}
      onClose={onClose}
      onCloseFocusRef={onCloseFocusRef}
    />
  );
};

export const FavoriteButtonWrapper = ({ workOrWorkId, ...boxProps }: { workOrWorkId: Work | string } & BoxProps) => {
  const librarySystem = useLibrarySystemClient();
  const isBibliofil = librarySystem?.config.type === "bibliofil";

  if (!isBibliofil) return null;

  return (
    <Box zIndex="1000" {...boxProps}>
      <FavoriteButton workOrWorkId={workOrWorkId} />
    </Box>
  );
};
