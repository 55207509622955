import { DocumentType, Language, Platform } from "@biblioteksentralen/cordata";
import { Box, BoxProps, Flex, FormLabel, HStack, Text, VStack } from "@biblioteksentralen/react";
import { useTranslation } from "../../../utils/hooks/useTranslation";
import { getDocumentTypeIndefiniteNoun, getDocumentTypeLabel } from "../../cordata/documentTypes";
import { getLanguagesSignature, languageListsEqual } from "../../cordata/languages";
import { reservationFullWidthBreakpoint } from "./ReservationContainer";
import { OnSelectVariant, VariantButton } from "./VariantButton";
import { ExternalContentService } from "./externalContentService";

export type WorkVariant = {
  documentType: DocumentType;
  languagesList?: Language[];
  platforms: Platform[] | undefined;
  externalContentService?: ExternalContentService | undefined;
};

type ReservationVariantButtonsProps = {
  isDisabled: boolean;
  languageAndDocumentTypeInSameButton: boolean;
  currentLanguagesList: Language[] | undefined;
  currentDocumentType: DocumentType | undefined;
  onSelectVariant: OnSelectVariant;
  workVariants: WorkVariant[];
};

export const ReservationVariantButtons = ({
  isDisabled,
  languageAndDocumentTypeInSameButton,
  currentLanguagesList,
  currentDocumentType,
  onSelectVariant,
  workVariants,
  ...boxProps
}: ReservationVariantButtonsProps & BoxProps) => {
  const { t, ts } = useTranslation();

  const isMusicDocumentType =
    currentDocumentType?.format.toLowerCase() === "musikk" ||
    currentDocumentType?.format.toLowerCase() === "musikktrykk";

  if (workVariants.length === 1 && workVariants[0]) {
    return (
      <VStack spacing="0.25rem" alignItems="flex-start" {...boxProps}>
        <Text fontSize="sm" fontWeight="semibold" margin="1rem 0 0">
          {t("Tilgjengelig som")}
          {":"}
        </Text>
        <VariantButton
          workVariant={workVariants[0]}
          onSelectVariant={onSelectVariant}
          languageAndDocumentTypeInSameButton={languageAndDocumentTypeInSameButton}
          isSelected
        />
      </VStack>
    );
  }

  return (
    <Flex flexDirection="column" alignItems="flex-start" justifyContent="center" marginTop="0.5rem" {...boxProps}>
      <Box>
        <FormLabel
          htmlFor="work-view-banner-format-picker"
          id="work-view-banner-format-picker-id"
          fontSize="sm"
          fontWeight="semibold"
          marginBottom="0.25rem"
          margin={{ base: "0.5rem auto 0.25rem", [reservationFullWidthBreakpoint]: "1rem auto 0.25rem" }}
        >
          {languageAndDocumentTypeInSameButton && !isMusicDocumentType
            ? `${t("Velg")} ${ts(getDocumentTypeIndefiniteNoun(currentDocumentType))}`
            : t("Velg format")}
          {":"}
        </FormLabel>
        <HStack
          id="work-view-banner-format-picker"
          role="radiogroup"
          aria-labelledby="work-view-banner-format-picker-id"
          flexWrap="wrap"
          spacing="0.75rem"
          alignItems="center"
          justifyContent="center"
        >
          {workVariants.map((workVariant) => (
            <VariantButton
              key={`${getDocumentTypeLabel(workVariant.documentType)}${
                languageAndDocumentTypeInSameButton && workVariant.languagesList
                  ? `-${getLanguagesSignature(workVariant.languagesList)}`
                  : ""
              }`}
              workVariant={workVariant}
              languageAndDocumentTypeInSameButton={
                languageAndDocumentTypeInSameButton && workVariant.documentType.format !== "Dataspill"
              }
              onSelectVariant={onSelectVariant}
              isDisabled={isDisabled}
              isSelected={
                !!currentDocumentType &&
                getDocumentTypeLabel(currentDocumentType) === getDocumentTypeLabel(workVariant.documentType) &&
                (!languageAndDocumentTypeInSameButton ||
                  !workVariant.languagesList ||
                  languageListsEqual(workVariant.languagesList, currentLanguagesList))
              }
            />
          ))}
        </HStack>
      </Box>
    </Flex>
  );
};
