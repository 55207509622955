import { Patron } from "@libry-content/redia-platform";
import { useEffect, useState } from "react";
import { PickupLocation } from "../../../components/minside/dataFetchers/useLocations";

export const useSelectedLibrary = (pickupLocations: PickupLocation[], user: Patron | undefined) => {
  const [isInitialisingLibrary, setIsInitialisingLibrary] = useState(false);
  const [selectedLibrary, setSelectedLibrary] = useState<PickupLocation | undefined>();

  const pickupLibrary = pickupLocations.find(({ code }) => code === user?.pickupLocation.branchCode);

  useEffect(() => {
    if (isInitialisingLibrary || selectedLibrary || !user || pickupLocations.length < 1) return;
    setIsInitialisingLibrary(true);

    if (!pickupLibrary) {
      setSelectedLibrary(pickupLocations[0]);
      return;
    }

    setSelectedLibrary(pickupLibrary);
  }, [isInitialisingLibrary, selectedLibrary, user, pickupLocations, pickupLibrary]);

  useEffect(() => {
    if (!isInitialisingLibrary || !selectedLibrary) return;
    setIsInitialisingLibrary(false);
  }, [isInitialisingLibrary, selectedLibrary]);

  const selectedLibraryLoading = isInitialisingLibrary || (!!user && !selectedLibrary);

  return { selectedLibrary, setSelectedLibrary, selectedLibraryLoading };
};
