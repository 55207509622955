import { CordataWork as CordataWorkI } from "@biblioteksentralen/bmdb-search-client";
import { getWorkData } from "@libry-content/integrations";
import { CordataWork } from "../../../searchAndCollection/cordata/CordataWork";
import { getDocumentTypes } from "../../../searchAndCollection/cordata/manifestations";
import { MinsidePublication } from "./minsidePublicationMap";

export type MinsideWork = {
  id: string;
  publicationId: string;
  coverUrl: string | undefined;
  title: string;
  creators: string[] | undefined;
  mediaTypes: string[];
  workUrl: string | undefined;
  workId: string | undefined;
};

type MinsideWorkMap = {
  id: string;
  publicationId: string;
  work: CordataWorkI;
  representativeManifestationId: string;
};

export function minsideWorkMap({
  id,
  publicationId,
  work,
  representativeManifestationId,
}: MinsideWorkMap): MinsidePublication {
  const workTriple = new CordataWork(work).getWorkTriple({ manifestationId: representativeManifestationId });

  const workData = workTriple && getWorkData(workTriple);
  if (!workData) {
    console.error("Missing work data in minsideWorkMap", { id, publicationId, work, representativeManifestationId });
  }
  const author = workData?.mainContributor?.agent.name.nb ?? workData?.mainContributor?.agent.name.nn;
  const documentTypes = getDocumentTypes(work).map(({ format }) => format);

  return {
    id,
    publicationId,
    coverUrl: workData?.imageUrl,
    title: workData?.formattedTitle ?? "Ukjent tittel",
    creators: author ? [author] : [],
    mediaTypes: documentTypes ?? ["Ukjent"],
    workUrl: `/samling/verk/${work.id}`,
    workId: work.id,
  };
}
