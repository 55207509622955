import {
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverProps,
  PopoverTrigger,
  Portal,
} from "@biblioteksentralen/react";
import { ReactNode } from "react";

type Props = {
  children: ReactNode;
  heading?: string;
  footer?: ReactNode;
  popoverTrigger: ReactNode;
} & PopoverProps;

const PopoverContainer = ({ heading, footer, children, popoverTrigger, ...popoverProps }: Props) => (
  <Popover {...popoverProps}>
    <PopoverTrigger>{popoverTrigger}</PopoverTrigger>
    <Portal>
      <PopoverContent
        marginX={{ base: "2.5vw", sm: undefined }}
        width={{ base: "90vw", sm: undefined }}
        maxW="30rem"
        boxShadow="0 2rem 5rem rgba(0,0,0,0.3)"
      >
        {heading && (
          <PopoverHeader fontWeight="semibold" fontSize="lg">
            {heading}
          </PopoverHeader>
        )}
        <PopoverArrow />
        <PopoverCloseButton margin=".25rem" />
        <PopoverBody marginTop={heading ? "unset" : "1rem"} padding="1rem .75rem">
          {children}
        </PopoverBody>
        {footer && (
          <PopoverFooter display="flex" justifyContent="flex-end">
            {footer}
          </PopoverFooter>
        )}
      </PopoverContent>
    </Portal>
  </Popover>
);

export default PopoverContainer;
