import { DocumentType } from "@biblioteksentralen/cordata";
import { BookRedia, GameRedia } from "@biblioteksentralen/icons";
import { Button, Text, VStack, colors } from "@biblioteksentralen/react";
import { capitalize } from "radash";
import { Disc, FileText, Headphones, Icon, Music, Video } from "react-feather";
import { Translate, useTranslation } from "../../../utils/hooks/useTranslation";
import { getDocumentTypeLabel, getPlatformsLabel } from "../../cordata/documentTypes";
import { LanguagesSignature, formatLanguagesList, getLanguagesSignature } from "../../cordata/languages";
import { ExternalContentService } from "./externalContentService";
import { WorkVariant } from "./ReservationVariantButtons";

const documentTypeIcons: Record<string, Icon> = {
  Bok: BookRedia,
  "E-bok": FileText,
  Lydbok: Disc,
  "Kassett Lydbok": Headphones,
  "E-lydbok": Headphones,
  Film: Video,
  Dataspill: GameRedia,
  CD: Music,
  LP: Music,
  Kassett: Music,
  Musikktrykk: Music,
};

export type OnSelectVariant = (
  languagesSignature: LanguagesSignature | undefined,
  documentTypeCode: DocumentType["code"] | undefined,
  externalContentService?: ExternalContentService["originName"]
) => void;

type VariantButtonProps = {
  workVariant: WorkVariant;
  isDisabled?: boolean;
  languageAndDocumentTypeInSameButton?: boolean;
  isSelected: boolean;
  onSelectVariant: OnSelectVariant;
};

export const VariantButton = ({
  workVariant,
  onSelectVariant,
  isDisabled,
  languageAndDocumentTypeInSameButton,
  isSelected,
}: VariantButtonProps) => {
  const { t } = useTranslation();
  const label = getLabel(workVariant, t);
  const Icon = documentTypeIcons[label] ?? documentTypeIcons[workVariant.documentType.format];

  const background = isSelected ? colors.accentGreen : colors.white;
  const hoverBackground = isSelected ? colors.accentGreen : colors.grey10;
  const fontColor = isSelected ? colors.white : colors.black;
  const hoverFontColor = isSelected ? colors.white : colors.black;
  const borderColor = isSelected ? undefined : colors.black;
  const cursor = isSelected ? "default" : "pointer";

  const languagesName =
    languageAndDocumentTypeInSameButton && workVariant.languagesList
      ? formatLanguagesList(workVariant.languagesList)
      : undefined;
  const languagesSignature =
    languageAndDocumentTypeInSameButton && workVariant.languagesList
      ? getLanguagesSignature(workVariant.languagesList)
      : undefined;

  const labelWithLanguage = `${label}, ${languagesName}`;

  return (
    <VStack
      as={Button}
      role="radio"
      aria-checked={isSelected}
      aria-label={languagesName ? labelWithLanguage : label}
      background={background}
      isDisabled={isDisabled}
      color={fontColor}
      borderRadius="md"
      borderColor={borderColor}
      spacing="0.125rem"
      cursor={cursor}
      paddingInlineStart="0.25rem"
      paddingInlineEnd="0.25rem"
      minWidth={languagesName ? "3.75rem" : "3.5rem"}
      minHeight={languagesName ? "4.25rem" : "3.5rem"}
      whiteSpace="normal"
      onClick={() =>
        onSelectVariant(
          languagesSignature,
          workVariant.documentType.code,
          workVariant.externalContentService?.originName
        )
      }
      _hover={{ background: hoverBackground, color: hoverFontColor }}
      _disabled={{
        color: `${fontColor} !important`,
        backgroundColor: `${background} !important`,
        opacity: 0.6,
      }}
    >
      {!!languagesName && <Text fontSize="xs">{languagesName}</Text>}
      {!!Icon && <Icon width="1.5rem" height="1.5rem" aria-hidden />}
      <Text fontSize="xs" display="flex" flexDirection="column">
        {label.split(" ").map((labelPart, index) => (
          <span key={index}>{labelPart}</span>
        ))}
      </Text>
    </VStack>
  );
};

const getLabel = ({ platforms, externalContentService, documentType }: WorkVariant, t: Translate["t"]) => {
  if (documentType.format === "Musikktrykk") return t("Notetrykk");
  if (platforms && platforms.length > 0) return getPlatformsLabel(platforms);
  if (externalContentService) return capitalize(externalContentService.originName);
  return getDocumentTypeLabel(documentType);
};
