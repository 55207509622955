import { DocumentType, Language, Work } from "@biblioteksentralen/cordata";
import { Box, Button, Flex, StackProps, useBoolean } from "@biblioteksentralen/react";
import { sortByMultiple } from "@biblioteksentralen/utils";
import { sift, unique } from "radash";
import { useState } from "react";
import { useTranslation } from "../../../utils/hooks/useTranslation";
import { getDocumentTypeLabel } from "../../cordata/documentTypes";
import {
  LanguagesSignature,
  formatLanguagesList,
  getLanguagesSignature,
  getSortedLanguagesListIndex,
  languageListsEqual,
} from "../../cordata/languages";
import { getFormatOrderIndex, getRelevantManifestations } from "../../cordata/manifestations";
import { useSearchConfig } from "../../hooks/useSearchConfig";
import { LibraryInfo } from "../../sanityQueries";
import { HoldingText, showHoldingText } from "../HoldingText";
import { ManifestationStatusForUser } from "../manifestations/ManifestationStatusForUser";
import { useManifestationStatusForUser } from "../manifestations/useManifestationStatusForUser";
import { FindInLibraryModal } from "../works/FindInLibraryModal";
import { useWorkViewData } from "../works/WorkViewBanner/useWorkViewData";
import { ReservationButton } from "./ReservationButton";
import { ReservationContainer, reservationFullWidthBreakpoint } from "./ReservationContainer";
import { ReservationLanguageMenu } from "./ReservationLanguageMenu";
import { ReservationVariantButtons, WorkVariant } from "./ReservationVariantButtons";
import { useCurrentVariantState } from "../works/WorkViewBanner/useCurrentVariantCharacteristics";
import {
  ExternalContentService,
  supportedExternalContentServices,
  getExternalContentService,
} from "./externalContentService";
import { getNewVariantParameters } from "./Reservation";

export const getLanguagesForDocumentType = ({ expressions = [] }: Work, documentTypeCode: DocumentType["code"]) =>
  unique(
    expressions
      .filter(({ manifestations = [], aggregateManifestations = [] }) =>
        [...manifestations, ...aggregateManifestations].some(
          ({ documentType }) => documentTypeCode === documentType?.code
        )
      )
      .map(({ languages }) => languages),
    getLanguagesSignature
  );

export type IdentifierForDocumentType = { languages: Language[]; externalContentService?: ExternalContentService };
//Allowing for multiple identifiers for document type, as both language and external content service are relevant for choice of manifestation
export const getIdentifiersForDocumentType = (
  { expressions = [] }: Work,
  documentTypeCode: DocumentType["code"]
): IdentifierForDocumentType[] => {
  const expressionsContainingDocumentCode = expressions.filter(
    ({ manifestations = [], aggregateManifestations = [] }) =>
      [...manifestations, ...aggregateManifestations].some(
        ({ documentType }) => documentTypeCode === documentType?.code
      )
  );

  const documentTypeIsExternalContentService = supportedExternalContentServices.find(
    ({ documentTypeCode: serviceDocumentTypeCode }) => documentTypeCode === serviceDocumentTypeCode
  );

  const existingIdentifiers: IdentifierForDocumentType[] = unique(
    expressionsContainingDocumentCode.flatMap(({ languages, manifestations = [], aggregateManifestations = [] }) =>
      documentTypeIsExternalContentService
        ? sift(
            [...manifestations, ...aggregateManifestations].map(({ _origin }) => getExternalContentService(_origin))
          ).map((externalContentService) => ({ languages, externalContentService }))
        : { languages }
    ),
    //toKey function for asserting uniqueness of array items
    (identifier: IdentifierForDocumentType) =>
      `${getLanguagesSignature(identifier.languages)}${identifier.externalContentService ?? ""}`
  );

  return existingIdentifiers;
};

const getSortedWorkVariants = (work: Work, documentTypes: DocumentType[]): WorkVariant[] => {
  const unsortedVariants = documentTypes.flatMap((documentType) =>
    getIdentifiersForDocumentType(work, documentType.code).map(({ languages, externalContentService }) => ({
      languagesList: languages,
      documentType,
      platforms: undefined,
      externalContentService,
    }))
  );

  return sortByMultiple(
    unsortedVariants,
    ({ documentType }) => getFormatOrderIndex(documentType.format),
    ({ languagesList }) => getSortedLanguagesListIndex(languagesList ?? []),
    ({ externalContentService }) =>
      supportedExternalContentServices.findIndex(({ originName }) => originName === externalContentService?.originName)
  );
};

type MovieReservationProps = {
  work: Work;
  libraries: LibraryInfo[];
  hasWorkHoldings: boolean;
} & StackProps;

/**
 * For movies the order of choice is oppsite of the usual: format -> language.
 * Therefore the logic is different than for other reservations and we use a separate component.
 */
export const MovieReservation = ({ work, libraries, hasWorkHoldings, ...stackProps }: MovieReservationProps) => {
  const { t } = useTranslation();
  const [findInLibraryModalOpen, { on: openFindInLibraryModal, off: closeFindInLibraryModal }] = useBoolean();
  const { isSearchIntegrationEnabled } = useSearchConfig();

  const {
    documentTypes,
    currentLanguagesList,
    currentLanguagesSignature,
    currentDocumentType,
    currentExternalContentService,
    representativeManifestation,
  } = useWorkViewData(work);

  //using the useCurrentVariantState hook here instead of using currentVariantState and updateCurrentVariantState from useWorkViewData to properly display loading
  const [currentVariantState, updateCurrentVariantState] = useCurrentVariantState();

  const relevantManifestations = getRelevantManifestations(work, currentLanguagesList, currentDocumentType);

  const currentDocumentTypeCode = currentDocumentType?.code;

  const manifestationIds = relevantManifestations.map(({ id }) => id);
  const manifestationStatusForUser = useManifestationStatusForUser(relevantManifestations);

  const [languagesBeingSelected, setLanguagesBeingSelected] = useState<LanguagesSignature | undefined>();
  const isSelectingLanguage = !!languagesBeingSelected && currentVariantState?.språk !== currentLanguagesSignature;

  const [documentTypeCodeBeingSelected, setDocumentTypeCodeBeingSelected] = useState<string | undefined>();
  const isSelectingDocumentType =
    !!documentTypeCodeBeingSelected && currentVariantState?.type !== currentDocumentTypeCode;

  const onSelectVariant = (
    languagesSignature?: LanguagesSignature,
    documentTypeCode?: DocumentType["code"],
    externalContentServiceOriginName?: ExternalContentService["originName"]
  ) => {
    if (
      languageListsEqual(currentLanguagesList, languagesSignature) &&
      (!documentTypeCode || documentTypeCode === currentDocumentTypeCode)
    ) {
      return; // No change
    }

    const variantParameters = getNewVariantParameters(
      work,
      documentTypes,
      currentDocumentTypeCode,
      languagesSignature,
      documentTypeCode,
      externalContentServiceOriginName
    );

    if (variantParameters.type) setDocumentTypeCodeBeingSelected(variantParameters.type);
    if (variantParameters.språk) setLanguagesBeingSelected(variantParameters.språk);

    updateCurrentVariantState(variantParameters);
  };

  const loading = isSelectingLanguage || isSelectingDocumentType || !manifestationStatusForUser.isReady;

  const workVariants = getSortedWorkVariants(work, documentTypes);

  // Put language and document type in the same button(s) if there is less than five options total
  // and if the langauge labels are less than 10 characters
  const languageAndDocumentTypeInSameButton =
    workVariants.length < 4 &&
    workVariants.every(({ languagesList }) => (formatLanguagesList(languagesList ?? [])?.length ?? 0) < 10);

  const currentAvailableLanguages = currentDocumentTypeCode
    ? getLanguagesForDocumentType(work, currentDocumentTypeCode)
    : [];

  const variantsUniqueInDocumentType = unique(workVariants, ({ documentType }) => getDocumentTypeLabel(documentType));

  return (
    <ReservationContainer documentType={currentDocumentType} {...stackProps}>
      <Box width="100%" position="relative">
        <form aria-label="form">
          {variantsUniqueInDocumentType.length > 0 && (
            <ReservationVariantButtons
              languageAndDocumentTypeInSameButton={languageAndDocumentTypeInSameButton}
              currentLanguagesList={currentLanguagesList}
              currentDocumentType={currentDocumentType}
              onSelectVariant={onSelectVariant}
              workVariants={variantsUniqueInDocumentType}
              isDisabled={isSelectingDocumentType || isSelectingLanguage}
              marginBottom={{ base: "0.75rem", [reservationFullWidthBreakpoint]: "1rem" }}
            />
          )}

          {!languageAndDocumentTypeInSameButton && currentAvailableLanguages.length > 1 && (
            <Box marginBottom="0.75rem">
              <ReservationLanguageMenu
                work={work}
                isDisabled={isSelectingLanguage || isSelectingDocumentType}
                languages={currentAvailableLanguages}
                currentLanguagesList={currentLanguagesList}
                onSelectVariant={onSelectVariant}
              />
            </Box>
          )}

          {showHoldingText(manifestationStatusForUser, currentExternalContentService) && (
            <HoldingText
              work={work}
              manifestationIds={manifestationIds}
              marginBottom="0.5rem"
              justifyContent="center"
              alignItems="center"
              textProps={{ fontWeight: "normal", fontSize: "md" }}
              separateLines
            />
          )}

          <ManifestationStatusForUser
            manifestationStatusForUser={manifestationStatusForUser}
            documentType={currentDocumentType}
            marginBottom="0.5rem"
          />

          <ReservationButton
            work={work}
            manifestations={relevantManifestations}
            manifestationStatusForUser={manifestationStatusForUser}
            representativeManifestation={representativeManifestation}
            externalContentService={currentExternalContentService}
            documentType={currentDocumentType}
            loading={loading}
            height="3rem"
          />
        </form>

        {isSearchIntegrationEnabled && (
          <Flex justifyContent="center" marginTop="0.75rem">
            <Button variant="secondary" width="100%" height="3rem" fontSize="lg" onClick={openFindInLibraryModal}>
              {t("Finn i biblioteket")}
            </Button>
          </Flex>
        )}
      </Box>

      <FindInLibraryModal
        isOpen={findInLibraryModalOpen}
        onClose={closeFindInLibraryModal}
        representativeManifestation={representativeManifestation}
        manifestationIds={manifestationIds}
        work={work}
        libraries={libraries}
        isRepresentedWork
      />
    </ReservationContainer>
  );
};
