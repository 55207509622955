import { getCanonicalLanguageCode } from "@libry-content/localization";
import { useTranslation } from "./useTranslation";
import { languageCodesEquivalent } from "./usePublicationLangAttribute";
import { Work } from "@biblioteksentralen/cordata";
import { useWorkUrlLanguage } from "../../searchAndCollection/components/works/WorkPreview";

/**
 * Return language-code in ISO 639-1 format. IE. "en", "nb", "sv".
 * If the work has a different language than the current language, return the work language, otherwise undefined.
 */
export const useWorkLangAttribute = (work: Work): string | undefined => {
  const { lang } = useTranslation();
  const representativeLanguages = useWorkUrlLanguage(work);
  const workLanguageCode = getCanonicalLanguageCode(representativeLanguages);
  const showPublicationLanguage = !!workLanguageCode && !languageCodesEquivalent(workLanguageCode, lang);

  return showPublicationLanguage ? workLanguageCode : undefined;
};
