import useSWR from "swr";
import { useRediaPlatformContext } from "../../../rediaPlatform/RediaPlatformProvider";
import { Locations } from "@libry-content/redia-platform";

type PickupBranch = Locations["branches"][number];
type PickupSection = Locations["sections"][number]["elements"][number];
export type PickupLocation = PickupBranch | PickupSection;

export const useLocations = () => {
  const { rediaPlatform } = useRediaPlatformContext();
  const swrCacheKey = rediaPlatform ? "rediaPlatform:locations" : null;
  return useSWR(swrCacheKey, () => rediaPlatform?.getLocations());
};

export const usePickupLocations = (): PickupLocation[] => {
  const { data } = useLocations();

  const pickupBranches = data?.branches?.filter((branch) => branch.pickup.allow) ?? [];

  const pickupSections =
    data?.sections.map((section) => section.elements.filter((element) => element.pickup.allow)).flat() ?? [];

  return [...pickupBranches, ...pickupSections];
};
