import { Work } from "@biblioteksentralen/cordata";
import { mapValues, sift, unique } from "radash";
import { useCallback, useMemo } from "react";
import { createGlobalState } from "react-use";
import useSWR from "swr";
import { useCommonData } from "../../components/layout/CommonDataProvider";
import { useRediaPlatformContext } from "../../rediaPlatform/RediaPlatformProvider";
import { PublicationHoldings } from "@libry-content/redia-platform";

const hasCode = (err: unknown): err is { code: number } =>
  !!err && typeof err === "object" && "code" in err && typeof err?.["code"] === "number";

const getLibraryCatalogueRecords = ({ expressions = [] }: Work, manifestationIds?: string[]) =>
  expressions.flatMap(({ manifestations = [], aggregateManifestations = [] }) =>
    [...manifestations, ...aggregateManifestations]
      .filter(({ id }) => !manifestationIds || manifestationIds.includes(id))
      .flatMap(({ libraryCatalogueRecords = [] }) => libraryCatalogueRecords)
  );

const getHoldingsCacheKey = (localIds: string[], siteId: string | undefined, isReady: boolean) =>
  !isReady || !siteId || localIds.length === 0 ? null : `/holdings/site/${siteId}/localIds/${localIds.join("-")}`;

const maxRetries = 3;

// This hook is used in several places locally, while the swr cache key is global.
// We use global state to keep track of which cache keys have ended up in an error state.
const useHoldingsCacheKeysWithError = createGlobalState<string[]>([]);

export const isStandardPublicationHoldings = (holding?: PublicationHoldings) =>
  typeof holding === "object" && holding?.type === "publication";

export const useHoldingsData = (work: Work, manifestationIds: string[] | undefined) => {
  const { site } = useCommonData();
  const { rediaPlatform } = useRediaPlatformContext();

  const libraryCatalogueRecords = getLibraryCatalogueRecords(work, manifestationIds);
  const localIds = unique(libraryCatalogueRecords.map(({ localRecordId }) => localRecordId));

  const holdingsCacheKey = getHoldingsCacheKey(localIds, site?._id, !!rediaPlatform?.getHoldings);
  const [holdingsCacheKeysWithError, setHoldingsCacheKeysWithError] = useHoldingsCacheKeysWithError();
  const hasError = !!holdingsCacheKey && holdingsCacheKeysWithError.includes(holdingsCacheKey);

  const onUnrecoverableError = useCallback(
    (err: unknown) => {
      console.error("useHoldingsData", err);
      setHoldingsCacheKeysWithError((current) => sift([...current, holdingsCacheKey]));
    },
    [holdingsCacheKey, setHoldingsCacheKeysWithError]
  );

  const {
    data: holdingsData,
    isLoading: holdingsDataLoading,
    mutate: onAfterHoldingsUpdate,
  } = useSWR(holdingsCacheKey, () => rediaPlatform?.getHoldings(localIds), {
    onErrorRetry: (err: unknown, key, config, revalidate, { retryCount }) => {
      // No retry from error "Customer not found" (see https://configuration-dev.redia.dk/docs/)
      if (hasCode(err) && err.code === 150) return onUnrecoverableError(err);
      // Max retries
      if (retryCount >= maxRetries) return onUnrecoverableError(err);
      // Retry with exponential backoff
      return setTimeout(() => revalidate({ retryCount }), 2000 + 1000 * retryCount ** 2);
    },
  });

  const holdingsWithLocations = useMemo(
    () =>
      holdingsData
        ? mapValues(holdingsData, (data) => (isStandardPublicationHoldings(data) ? data : undefined))
        : undefined,
    [holdingsData]
  );

  const isLoading = !hasError && (holdingsDataLoading || (!!localIds?.length && !holdingsData));

  return { holdingsData: holdingsWithLocations, hasError, isLoading, onAfterHoldingsUpdate };
};
