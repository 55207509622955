import {
  PatronListItem,
  PatronLoan,
  PatronLoanHistoryItem,
  PatronReservation,
  Publication,
} from "@libry-content/redia-platform";

export type MinsidePublication = {
  id: string;
  publicationId: string;
  coverUrl: string | undefined;
  title: string;
  creators: string[] | undefined;
  mediaTypes: string[];
  workUrl: string | undefined;
  workId: string | undefined;
};

type MinsidePublicationMap = {
  id: string;
  publicationId: string;
  publication?: Publication;
  patronPublicationItem?: PatronLoan | PatronReservation | PatronLoanHistoryItem | PatronListItem;
};

const isPatronListItem = (patronPublicationItem: unknown): patronPublicationItem is PatronListItem => {
  return (
    typeof patronPublicationItem === "object" && patronPublicationItem !== null && "workId" in patronPublicationItem
  );
};

export function minsidePublicationMap({
  id,
  publicationId,
  publication,
  patronPublicationItem,
}: MinsidePublicationMap): MinsidePublication {
  const workId = publication?.works?.[0]?.identifier;
  return {
    id,
    publicationId,
    coverUrl: publication?.images?.[0]?.url,
    title:
      titleWithPartNumber(publication) ??
      (isPatronListItem(patronPublicationItem)
        ? `ID ${publicationId}`
        : patronPublicationItem?.fallbackMetadata?.titles?.[0]?.main) ??
      `ID ${publicationId}`,
    creators:
      publication?.creators?.map(({ agent }) => {
        return agent.type === "person" ? agent?.fullName : "";
      }) ??
      (isPatronListItem(patronPublicationItem)
        ? []
        : patronPublicationItem?.fallbackMetadata?.creators?.map(({ agent }) => {
            return agent.type === "person" ? `${agent.givenName} ${agent.surname}` : "";
          })),
    mediaTypes: publication?.mediaTypes ? publication?.mediaTypes?.map(({ name }) => name) : ["Ukjent"],
    workUrl: workId ? `/samling/verk/${workId}` : undefined,
    workId,
  };
}

const titleWithPartNumber = (publication?: Publication) => {
  const hasPartNumber = publication?.titles[0]?.part?.number;
  return hasPartNumber
    ? `${publication?.titles[0]?.main}. ${publication?.titles[0]?.part?.number}`
    : publication?.titles[0]?.main;
};
