import { BranchInformation } from "@libry-content/redia-platform";
import { sift } from "radash";
import { useCallback, useMemo } from "react";
import useSWR from "swr";
import { useCommonData } from "../../components/layout/CommonDataProvider";
import { useRediaPlatformContext } from "../../rediaPlatform/RediaPlatformProvider";

type Identifier = BranchInformation["externals"]["identifiers"][number];

/**
 * This is a temporary fix because some Libry Content libraries are considered sub-departments of branches
 * in Bibliofil and thus at the moment not in the payload of the Redia API route /branches/information
 *
 * https://biblioteksentralen.slack.com/archives/C046SJJ558R/p1700036148197549
 */
const hardCodedIsilCodesToBranchCodes = {
  "NO-2113301": "bhav", // Hjelmeland, Årdal
  "NO-2113416": "busv", // Suldal, Suldalsosen
  "NO-2113414": "burv", // Suldal, Ropeidhalvøya
  "NO-2113411": "buev", // Suldal, Erfjord
  "NO-2113412": "bujv", // Suldal, Jelsa
  "NO-2113415": "bunv", // Suldal, Nesflaten
};

const hasIsilCodes =
  (isilCode: string | undefined) =>
  ({ type, value }: Identifier) =>
    !!isilCode && type === "isil" && value === isilCode;

export const useLibrarySystemBranches = () => {
  const { rediaPlatform, isSessionReady, user } = useRediaPlatformContext();
  const { site } = useCommonData();
  const isReady = !!site && isSessionReady && !!rediaPlatform && !!user;

  const { data, error, isLoading } = useSWR(
    isReady ? `/${site._id}/branches` : null,
    () => rediaPlatform?.getBranches() ?? null,
    { onError: (err) => console.error(new Error("Failed to get library system branches", { cause: err })) }
  );

  const branches = useMemo(() => sift(Object.values(data ?? {})), [data]);

  const isilCodeFromBranchCode = useCallback(
    (branchCode: string | undefined) =>
      branches.find(({ code }) => code === branchCode)?.externals.identifiers.find(({ type }) => type === "isil")
        ?.value,
    [branches]
  );

  const branchCodeFromIsilCode = useCallback(
    (isilCode: string | undefined) =>
      (hardCodedIsilCodesToBranchCodes as any)[isilCode ?? ""] ??
      branches.find(({ externals }) => externals.identifiers.find(hasIsilCodes(isilCode)))?.code,
    [branches]
  );

  return {
    branches,
    isilCodeFromBranchCode: !isReady || isLoading ? undefined : isilCodeFromBranchCode,
    branchCodeFromIsilCode: !isReady || isLoading ? undefined : branchCodeFromIsilCode,
    error,
    isLoading,
  };
};
