import { Work } from "@biblioteksentralen/cordata";
import { bmdbFetcher, LibrarySystemClient } from "@libry-content/integrations";
import { PatronListAndItemsBody, PatronListItem, RediaPlatform } from "@libry-content/redia-platform";
import useSWRImmutable from "swr/immutable";
import { swrUserNamespace, useRediaPlatformContext } from "../../../rediaPlatform/RediaPlatformProvider";
import { getSearchConfig, SiteSearchConfig } from "../../../searchAndCollection/searchApi/searchConfig";
import { useLibrarySystemClient } from "../../../utils/hooks/useLibrarySystemClient";
import { useCommonData } from "../../layout/CommonDataProvider";
import { MinsidePublication, minsidePublicationMap } from "./minsidePublicationMap";
import { minsideWorkMap } from "./minsideWorkMap";
import { tryFetchPublications } from "./tryFetchPublications";

type MinSideFavoriteItem = {
  rediaItem: PatronListItem;
  work?: Work;
  representativeManifestationId?: string;
  publication?: MinsidePublication;
};

export type FavoriteListWithFavoriteItems = {
  name: string;
  id: string;
  items: MinSideFavoriteItem[];
};

export const useAllFavoriteLists = () => {
  const { user, rediaPlatform } = useRediaPlatformContext();
  const librarySystemClient = useLibrarySystemClient();
  const { site } = useCommonData();
  const searchConfig = getSearchConfig(site);
  const swrCacheKey = user && rediaPlatform ? [swrUserNamespace, user?.identifiers?.[0]?.value, "favorites"] : null;

  return useSWRImmutable(swrCacheKey, async () => {
    if (!rediaPlatform || !librarySystemClient) {
      throw new Error("Redia Platform and/or Library System Client not initialized");
    }

    const rediaLists = await rediaPlatform.getLists();

    const rediaListsWithRediaItemsPromises = rediaLists.lists.map(async (list) => {
      return await rediaPlatform.getList(list.id);
    });

    const rediaListsWithRediaItems = (await Promise.allSettled(rediaListsWithRediaItemsPromises))
      .filter((result) => result.status === "fulfilled")
      .map((result) => result.value.list);

    const rediaItems = rediaListsWithRediaItems.map((list) => list.items.map((item) => item)).flat();
    const rediaPublicationResponse = await tryFetchPublications(rediaPlatform, rediaItems);

    const rediaListsWithBmdbWorkPromises = rediaListsWithRediaItems.map((rediaList) =>
      mapRediaListToFavoriteItem(rediaList, librarySystemClient, searchConfig, rediaPublicationResponse)
    );

    const rediaListsWithBmdbWork = await Promise.all(rediaListsWithBmdbWorkPromises);

    return { lists: rediaListsWithBmdbWork, defaultListId: rediaLists.defaultListId };
  });
};

const mapRediaListToFavoriteItem = async (
  list: PatronListAndItemsBody["list"],
  librarySystemClient: LibrarySystemClient,
  searchConfig: SiteSearchConfig,
  rediaPublicationResponse: Awaited<ReturnType<RediaPlatform["getPublications"]>>
) => {
  const bmdbCatalogueRecordFilter = list.items.map(
    (rediaItem) => `${librarySystemClient.config.database}:${rediaItem.publicationId}`
  );

  const bmdbItems = await bmdbFetcher({
    operation: "searchWorks",
    siteSearchConfig: searchConfig,
    params: {
      filter: {
        "bmdb.catalogueRecord": bmdbCatalogueRecordFilter,
      },
    },
  });

  const combinedItems = list.items.map((rediaItem) => {
    const bmdbWork = bmdbItems?.results.find(({ work }) =>
      (work.expressions ?? []).some(({ manifestations = [], aggregateManifestations = [] }) =>
        [...manifestations, ...aggregateManifestations].some(({ libraryCatalogueRecords = [] }) =>
          libraryCatalogueRecords.some((record) => record.localRecordId === rediaItem.publicationId)
        )
      )
    );

    return {
      rediaItem: {
        id: rediaItem.id,
        publicationId: rediaItem.publicationId,
        workId: rediaItem.workId || bmdbWork?.work.id || "",
      } satisfies PatronListItem,
      work: bmdbWork?.work as Work,
      representativeManifestationId: bmdbWork?.representativeManifestationId,
      publication:
        bmdbWork?.work && bmdbWork?.representativeManifestationId
          ? minsideWorkMap({
              id: rediaItem.id,
              publicationId: rediaItem.publicationId,
              work: bmdbWork.work,
              representativeManifestationId: bmdbWork.representativeManifestationId,
            })
          : minsidePublicationMap({
              id: rediaItem.id,
              publicationId: rediaItem.publicationId,
              publication: rediaPublicationResponse[rediaItem.publicationId],
              patronPublicationItem: rediaItem,
            }),
    } satisfies MinSideFavoriteItem;
  });

  return {
    name: list.name,
    id: list.id,
    items: combinedItems,
  };
};

export const useFavoriteList = (listId: string) => {
  const { data, mutate, error, isLoading, isValidating } = useAllFavoriteLists();

  if (!data?.lists) return undefined;

  const favoriteList = data.lists.find((list) => list.id === listId);

  return { data: favoriteList, mutate, error, isLoading, isValidating, defaultListId: data.defaultListId };
};
