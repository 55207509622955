import { RediaPlatform } from "@libry-content/redia-platform";

type HasPublicationId = { publicationId: string };

export async function tryFetchPublications(rediaPlatform: RediaPlatform | undefined, data: HasPublicationId[]) {
  const publicationIds = data.map(({ publicationId }) => publicationId);
  if (!rediaPlatform) return {};
  try {
    return await rediaPlatform.getPublications(publicationIds);
  } catch (error) {
    console.error("Failed to fetch publications", error);
    return {};
  }
}
