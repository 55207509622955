import { Work } from "@biblioteksentralen/cordata";
import { AtLeastOne, Modify, isAtLeastOne } from "@biblioteksentralen/types";
import {
  StandardPublicationHoldingItem,
  StandardPublicationHoldingLocation,
  StandardPublicationHoldings,
} from "@libry-content/redia-platform";
import { sift, sort } from "radash";
import { useTranslation } from "../../../utils/hooks/useTranslation";
import { ManifestationSorter } from "../../cordata/manifestations";

import { useHoldingsData } from "../../hooks/useHoldingsData";
import { SortableManifestation } from "../../types";
import { PickupLocation } from "../../../components/minside/dataFetchers/useLocations";
import { TranslateSanityContent } from "@libry-content/localization";

export type ReservableHolding = {
  reserveId: string;
  availableState: StandardPublicationHoldingItem["availableState"];
  isilCode: string | undefined;
  branchName: string | undefined;
  manifestation: SortableManifestation;
};

const attemptChoiceByManifestationSorter = (
  items: AtLeastOne<ReservableHolding>,
  manifestationSorter: ManifestationSorter
): ReservableHolding | undefined => {
  const sortedManifestationIds = manifestationSorter(items.map(({ manifestation }) => manifestation)).map(
    ({ id }) => id
  );

  return sort(items, ({ manifestation }) => sortedManifestationIds.indexOf(manifestation.id))?.[0];
};

const negate =
  <T>(predicate: (item: T) => boolean) =>
  (item: T) =>
    !predicate(item);

const isAggregate = ({ manifestation }: ReservableHolding) => !!manifestation.isAggregateManifestation;

const isAvailable = ({ availableState }: ReservableHolding) => availableState === "available";

/**
 * Select a manifestation and one of its holdings for reservation given a list of manifestations, a selected library,
 * and a prioritization order of manifestations. The selection is done as follows:
 * - 1. Prioritize holdings by:
 *   - a) not an aggregate manifestation
 *   - b) available and in selected pickup library
 *   - c) available and in another library
 *   - d) unavailable but in selected pickup library
 *   - e) unavailable and in another library
 * - 2. Proritize holdings resulting from 1. by their manifestation,
 *      according to the supplied manifestation order
 *
 * Note that although we choose a specific holding *item* based on the above, we only supply a (less specific) *holding*
 * (via publicationId) as a reservation to the library system. This way the latter takes control of which item is
 * reserved, while we have attempted to chooose a holding for which it's possible to follow the above proirity.
 *
 * TODO: Make a prioritization between other libraries than the one selected for pickup, based on distances etc?
 */
const getPrioritizedPredicates = (inSelectedLibrary: ReservableHoldingPredicate): ReservableHoldingPredicate[][] => [
  [negate(isAggregate), inSelectedLibrary, isAvailable],
  [negate(isAggregate), negate(inSelectedLibrary), isAvailable],
  [negate(isAggregate), inSelectedLibrary, negate(isAvailable)],
  [negate(isAggregate), negate(inSelectedLibrary), negate(isAvailable)],
  [isAggregate, inSelectedLibrary, isAvailable],
  [isAggregate, negate(inSelectedLibrary), isAvailable],
  [isAggregate, inSelectedLibrary, negate(isAvailable)],
  [isAggregate, negate(inSelectedLibrary), negate(isAvailable)],
];

const normalizeLibraryName = (name: string | undefined) =>
  name
    ?.toLowerCase()
    ?.replace(/bibliotek|filial/, "")
    .trim();

const attemptToGuessIsilCode = (
  ts: TranslateSanityContent,
  pickupLocations: PickupLocation[],
  location: StandardPublicationHoldingLocation["location"],
  isilCodeFromBranchCode: ((branchCode: string | undefined) => string | undefined) | undefined
) => {
  const libraryFromLocation = pickupLocations.find(({ name }) => {
    const libryContentLibraryName = normalizeLibraryName(name);
    const locationLibraryName = normalizeLibraryName(location.branchName);
    return libryContentLibraryName && locationLibraryName
      ? libryContentLibraryName.match(locationLibraryName) ?? locationLibraryName.match(libryContentLibraryName)
      : undefined;
  });

  return libraryFromLocation?.code ? isilCodeFromBranchCode?.(libraryFromLocation.code) : undefined;
};

const holdingCanBeReserved = (
  holding: StandardPublicationHoldings
): holding is Modify<StandardPublicationHoldings, { reserveId: string }> => !!holding.reserveId;

type ReservableHoldingPredicate = (item: ReservableHolding) => boolean;

const attemptChoiceByPredicates = (
  items: ReservableHolding[],
  sorter: ManifestationSorter,
  predicates: ReservableHoldingPredicate[]
) => {
  const candidateItems = items.filter((item) => predicates.reduce((acc, predicate) => acc && predicate(item), true));

  if (isAtLeastOne(candidateItems)) {
    return attemptChoiceByManifestationSorter(candidateItems, sorter);
  }
};

export const useChosenReservableHolding = (
  pickupLocations: PickupLocation[],
  sorter: ManifestationSorter,
  work: Work,
  manifestations: AtLeastOne<SortableManifestation>,
  selectedLibrary: PickupLocation | undefined,
  isilCodeFromBranchCode: ((branchCode: string | undefined) => string | undefined) | undefined
) => {
  const { ts } = useTranslation();
  const { holdingsData } = useHoldingsData(
    work,
    manifestations.map(({ id }) => id)
  );

  if (!selectedLibrary || !holdingsData || !isilCodeFromBranchCode) return undefined;

  const reservableHoldings = sift(Object.values(holdingsData ?? {}))
    ?.filter(holdingCanBeReserved)
    .flatMap(({ locations, reserveId, publicationId }): ReservableHolding[] => {
      const manifestation = manifestations.find(({ libraryCatalogueRecords }) =>
        libraryCatalogueRecords?.some(({ localRecordId }) => localRecordId === publicationId)
      );
      if (!manifestation) return [];
      const holdingsWithLocation = locations.flatMap(({ location, items }) =>
        items.map(({ availableState }) => ({
          reserveId,
          availableState,
          isilCode: attemptToGuessIsilCode(ts, pickupLocations, location, isilCodeFromBranchCode) ?? undefined,
          branchName: location.branchName,
          manifestation,
        }))
      );

      const holdingWithoutLocation =
        locations.length === 0
          ? [
              {
                reserveId,
                availableState: "notAvailable" as StandardPublicationHoldingItem["availableState"],
                isilCode: undefined,
                branchName: undefined,
                manifestation,
              },
            ]
          : [];

      return holdingsWithLocation.length > 0 ? holdingsWithLocation : holdingWithoutLocation;
    });

  const inSelectedLibrary = ({ isilCode }: ReservableHolding) =>
    !!selectedLibrary.code && isilCode === isilCodeFromBranchCode(selectedLibrary.code);

  const predicatesList = getPrioritizedPredicates(inSelectedLibrary);

  return predicatesList.reduce(
    (result: ReservableHolding | undefined, predicates) =>
      result || attemptChoiceByPredicates(reservableHoldings, sorter, predicates),
    undefined
  );
};
