import { DocumentType } from "@biblioteksentralen/cordata";
import { Box, Heading, StackProps, VStack } from "@biblioteksentralen/react";
import { PropsWithChildren } from "react";
import { useTranslation } from "../../../utils/hooks/useTranslation";
import { useSitePalette } from "../../../utils/useSitePalette";
import { getDocumentTypeDefiniteNoun, getDocumentTypeIndefiniteNoun } from "../../cordata/documentTypes";

type ReservationContainerProps = StackProps & PropsWithChildren<{ documentType: DocumentType | undefined }>;

/**
 * Reservation module moves to a separate line at this point. We use explicit
 * breakpoints rather than flex-wrap etc, since the former allows styling the
 * module depending on whether it's on a separate line.
 */
export const reservationFullWidthBreakpoint = "lg";

export const ReservationContainer = ({ children, documentType, ...stackProps }: ReservationContainerProps) => {
  const { t, ts } = useTranslation();
  const palette = useSitePalette();

  return (
    <VStack
      as="section"
      alignItems="flex-start"
      padding="1.5rem 1.5rem 1.5rem"
      {...palette.colors.card.css}
      {...stackProps}
    >
      <Box maxWidth="20rem" width="100%" margin="0 auto" textAlign="center">
        <Heading
          as="h2"
          fontSize="xl"
          fontWeight="semibold"
          width="100%"
          marginBottom={{ base: "0.5rem", [reservationFullWidthBreakpoint]: "1rem" }}
          {...palette.colors.card.css}
        >
          {t("Lån")}{" "}
          {documentType?.format.toLowerCase() === "musikk" || documentType?.format.toLowerCase() === "musikktrykk"
            ? ts(getDocumentTypeIndefiniteNoun(documentType))
            : ts(getDocumentTypeDefiniteNoun(documentType))}
        </Heading>
        {children}
      </Box>
    </VStack>
  );
};
