import { Work, formatIsbdTitle } from "@biblioteksentralen/cordata";
import { HStack, Text, VStack } from "@biblioteksentralen/react";
import InternalLink from "../../../components/InternalLink";
import { useTranslation } from "../../../utils/hooks/useTranslation";
import { formatAgentName } from "../../cordata/agents";
import { WorkCoverImage } from "../WorkCoverImage";
import { ReservableHolding } from "./useChosenReservableHolding";

type ReservationModalAlreadyOnLoanProps = {
  work: Work;
  reservableHolding: ReservableHolding;
  hideImage?: boolean;
};

export const ReservationModalAlreadyOnLoan = ({
  work,
  hideImage,
  reservableHolding: { manifestation },
}: ReservationModalAlreadyOnLoanProps) => {
  const { t, ts } = useTranslation();

  return (
    <VStack as="section" spacing="2rem">
      <HStack spacing="1rem">
        <WorkCoverImage
          work={work}
          representativeManifestation={manifestation}
          usePlaceholder={hideImage}
          maxWidth="7rem"
        />
        <VStack alignItems="flex-start" spacing="0.75rem">
          <Text>
            {t("Du har allerede lånt")} {"“"}
            {formatIsbdTitle(manifestation)}
            {"”"} {t("av")} {work.contributors.map(({ agent }) => formatAgentName(ts, agent)).join(` ${t("og")} `)}
            {"."}
          </Text>
        </VStack>
      </HStack>
      <Text>
        {t("Husk at du alltid kan se og administrere dine lån på")}{" "}
        <InternalLink href="/min-side/laan">
          {t("Min side")}
          {"."}
        </InternalLink>
      </Text>
    </VStack>
  );
};
