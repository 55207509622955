import { DocumentType, Genre } from "@biblioteksentralen/cordata";
import { Box, BoxProps, ChakraProps, HStack, Tag } from "@biblioteksentralen/react";
import { sort } from "radash";
import { ReactNode } from "react";
import { ResolvedPaletteColor, useSitePalette } from "../../utils/useSitePalette";
import { getDocumentTypeLabel } from "../cordata/documentTypes";
import { getFormatOrderIndex } from "../cordata/manifestations";

type DocumentTypesProps = {
  documentTypes: DocumentType[];
  genres: Genre[];
} & BoxProps;

export const DocumentTypes = ({ documentTypes, genres, ...boxProps }: DocumentTypesProps) => {
  const palette = useSitePalette();
  const illustratedTag = getIllustratedTag(documentTypes, genres);

  const documentTypesSorted = sort(
    documentTypes.filter(({ format }) => !illustratedTag || !formatsReplacedByIllustratedTag.includes(format)),
    ({ format }) => getFormatOrderIndex(format)
  );

  const haveTagsOtherThanIllustrated = documentTypesSorted.length > 0;

  return (
    <Box {...boxProps}>
      {illustratedTag && (
        <Box as="ul" marginBottom={haveTagsOtherThanIllustrated ? "0.125rem" : "0"}>
          <TypeTag styleColors={palette.colors.lightaccent3}>{illustratedTag}</TypeTag>
        </Box>
      )}
      {haveTagsOtherThanIllustrated && (
        <HStack as="ul" maxWidth="12rem" flexWrap="wrap" spacing="0.5rem">
          {documentTypesSorted.map(getDocumentTypeLabel).map((label) => (
            <TypeTag key={label} aria-label={label} styleColors={palette.colors.lightaccent3}>
              {label}
            </TypeTag>
          ))}
        </HStack>
      )}
    </Box>
  );
};

export const TypeTag = ({
  children,
  styleColors,
  ...chakraProps
}: { children: ReactNode; styleColors: ResolvedPaletteColor } & ChakraProps) => {
  return (
    <Tag
      as="li"
      whiteSpace="nowrap"
      textTransform="uppercase"
      fontSize="2xs" // TODO: Is this too small?
      lineHeight="1.2"
      fontWeight="semibold"
      minHeight="unset"
      padding="0.125rem 0.25rem"
      borderRadius="sm"
      minWidth="0"
      background={styleColors.css.background}
      color={styleColors.css.color}
      {...chakraProps}
    >
      {children}
    </Tag>
  );
};

const getIllustratedTag = (documentTypes: DocumentType[], genres: Genre[]) => {
  if (!documentTypes.some(({ format }) => ["bok", "ebok"].includes(format?.toLowerCase().replace("-", "")))) {
    return undefined;
  }

  const illustratedBookGenres = [
    "1166092", //bildebøker
    "1166093", //pekebøker
    "1103158", //Kunstnerbøker,
    "1453728", //Bilderomaner
  ];
  const comicGenres = [
    "1166096", //Tegneserier
    "1166043", //Grafiske romaner
  ];

  const illustratedWorkGenres = "1203040";

  if (
    genres?.some((genre) => {
      return illustratedBookGenres.includes(genre.identifiers.bibbiAuthorityId ?? "");
    })
  ) {
    return "Illustrert bok";
  }

  if (
    genres?.some((genre) => {
      return comicGenres.includes(genre.identifiers.bibbiAuthorityId ?? "");
    })
  ) {
    return "Tegneserie";
  }

  if (
    genres?.some((genre) => {
      return illustratedWorkGenres === genre.identifiers.bibbiAuthorityId;
    })
  ) {
    return "Illustrert verk";
  }

  return undefined;
};

const formatsReplacedByIllustratedTag = ["Bok"];
