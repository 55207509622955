import { Work } from "@biblioteksentralen/cordata";
import { Alert, HStack, Spinner, VStack } from "@biblioteksentralen/react";
import { AtLeastOne } from "@biblioteksentralen/types";
import { PatronListInsertItem } from "@libry-content/redia-platform";
import { useState } from "react";
import {
  FavoriteListWithFavoriteItems,
  useAllFavoriteLists,
} from "../../../components/minside/dataFetchers/useFavorites";
import { usePickupLocations } from "../../../components/minside/dataFetchers/useLocations";
import CreateFavoriteInput from "../../../components/minside/favorite/CreateFavoriteInput";
import { useRediaPlatformContext } from "../../../rediaPlatform/RediaPlatformProvider";
import { useTranslation } from "../../../utils/hooks/useTranslation";
import { sortManifestationsByRelevance } from "../../cordata/manifestations";
import { useLibrarySystemBranches } from "../../hooks/useLibrarySystemBranches";
import { useSearchConfig } from "../../hooks/useSearchConfig";
import { SortableManifestation } from "../../types";
import { useChosenReservableHolding } from "../reservation/useChosenReservableHolding";
import { useSelectedLibrary } from "../reservation/useSelectedLibrary";
import FavoriteAddItemButton from "./FavoriteAddItemButton";
import { isItemInFavoriteList } from "./FavoriteButton";
import { useHoldingsData } from "../../hooks/useHoldingsData";

type Props = {
  work: Work;
  manifestations: AtLeastOne<SortableManifestation>;
  onClose: () => void;
};

const FavoritePopoverBody = ({ work, manifestations, onClose }: Props) => {
  const { t } = useTranslation();
  const { user, rediaPlatform } = useRediaPlatformContext();
  const { data: favorites, mutate: refreshFavorites, error } = useAllFavoriteLists();
  const [status, setStatus] = useState<"idle" | "loading" | "error">("idle");

  const pickupLocations = usePickupLocations();
  const { selectedLibrary } = useSelectedLibrary(pickupLocations, user);
  const { isilCodeFromBranchCode } = useLibrarySystemBranches();
  const { isSearchIntegrationEnabled } = useSearchConfig();

  const manifestationIds = manifestations.map(({ id }) => id);
  const { isLoading: workHoldingsLoading } = useHoldingsData(work, manifestationIds);

  // Should be the same for all manifestations in this context
  const documentType = manifestations.find(({ documentType }) => documentType?.format)?.documentType;
  if (!documentType?.format) throw new Error(`Can't find format from ${JSON.stringify(manifestations)}`);

  const noop = <T,>(item: T): T => item;

  const manifestationSorter = documentType?.format
    ? sortManifestationsByRelevance(work.title, undefined, [documentType.format])
    : noop;

  const chosenReservableHolding = useChosenReservableHolding(
    pickupLocations,
    manifestationSorter,
    work,
    manifestations,
    selectedLibrary,
    isilCodeFromBranchCode
  );

  const { reserveId } = chosenReservableHolding || {};

  const reset = () => {
    refreshFavorites();
    setStatus("idle");
    onClose();
  };

  if (workHoldingsLoading) return <Spinner />;

  if (!chosenReservableHolding)
    return <Alert status="warning">{t("Beklager, det er ikke mulig å legge til denne i listen akkurat nå.")}</Alert>;

  const handleAddItemToList = async (favoriteId: string, data: FavoriteListWithFavoriteItems) => {
    if (!rediaPlatform || !isSearchIntegrationEnabled) {
      console.error("Redia Platform not initialized or search integration not enabled");
      setStatus("error");
      return;
    }

    const { reserveId } = chosenReservableHolding || {};

    setStatus("loading");

    try {
      const item: PatronListInsertItem = { publicationId: reserveId, workId: work.id };

      if (!isItemInFavoriteList(work.id, data.items)) {
        await rediaPlatform.addItemToList(favoriteId, item);
        console.info("Succesfully added item in patronList");
        reset();
        return;
      }

      const publicationId = findItemWithWorkId(work.id, data)?.publicationId;
      if (publicationId) {
        await rediaPlatform.deleteItemFromList(favoriteId, publicationId);
        console.info("Succesfully deleted item in patronList");
        reset();
      }
    } catch (err) {
      setStatus("error");
      console.error(err);
    }
  };

  if (error) {
    return <Alert status="error">{t("Beklager, det skjedde en feil når vi skulle hente listene dine.")}</Alert>;
  }

  if (status === "error")
    return (
      <Alert status="error">
        {t("Beklager, det oppsto en ukjent feil.")} {t("Feilen er logget og vil snart bli fikset.")}
      </Alert>
    );

  return (
    <VStack alignItems="flex-start" spacing="1.5rem">
      <HStack gap=".5rem" wrap="wrap">
        {!favorites ? (
          <Spinner />
        ) : (
          favorites?.lists
            .map((list) => (
              <FavoriteAddItemButton
                key={list.id}
                publicationId={reserveId}
                workId={work.id}
                handleAddItemToList={handleAddItemToList}
                list={list}
              />
            ))
            .reverse()
        )}
        {status === "loading" && <Spinner />}
      </HStack>
      <CreateFavoriteInput
        mutate={refreshFavorites}
        onClose={onClose}
        autoAddItem={reserveId ? { publicationId: reserveId, workId: work.id } : undefined}
      />
    </VStack>
  );
};

const findItemWithWorkId = (workId: string, data: FavoriteListWithFavoriteItems) => {
  return data.items.find(({ rediaItem }) => rediaItem.workId === workId)?.rediaItem;
};

export default FavoritePopoverBody;
