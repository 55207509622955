import { Expression, Work } from "@biblioteksentralen/cordata";
import { SortableManifestation } from "../../types";
import { isAtLeastOne } from "@biblioteksentralen/types";

export type SortableManifestationWithPartNumber = SortableManifestation & { partNumber: string };

//Henter manifestasjoner delt opp på om de er deler av verket,
//enten ved tittelnummer (for eksempel "Bind 1", "Bind 2" osv. (Se for eksempel "Krig og fred" av Tolstoj))
//eller ved serienummer (for eksempel "3.1", "3.1" (Se for eksempel "Storm of Swords" fra "A Song of Ice and Fire"-serien)).
//Dersom ingen delmanifestasjoner finnes, returneres undefined.
export const getPartedAndWholeManifestations = (
  work: Work,
  manifestations: SortableManifestation[]
):
  | {
      //partedManifestations inkluderer eget felt for delnummer, hvor enten delnummer fra enten tittel eller uttrykk vil ligge, slik at disse kan behandles likt.
      partedManifestations: SortableManifestationWithPartNumber[];
      wholeManifestations: SortableManifestation[];
    }
  | undefined => {
  const manifestationsPartedOnTitleNumber: SortableManifestationWithPartNumber[] = manifestations
    .map((manifestation) => {
      const expression = work.expressions?.find((expression) =>
        [...(expression.manifestations ?? []), ...(expression.aggregateManifestations ?? [])].find(
          (expressionManifestation) => expressionManifestation.id === manifestation.id
        )
      );
      return { manifestation, expression, partNumber: manifestation.title.partNumber };
    })
    .filter(
      (
        manifestationWithPartNumber
      ): manifestationWithPartNumber is {
        manifestation: SortableManifestation;
        expression: Expression;
        partNumber: string;
      } => !!manifestationWithPartNumber.partNumber && !!manifestationWithPartNumber.expression
    )
    .sort((a, b) => a.expression.id.localeCompare(b.expression.id))
    .map((manifestationWithPartNumber) => ({
      ...manifestationWithPartNumber.manifestation,
      partNumber: manifestationWithPartNumber.partNumber,
    }));

  const manifestationsNotPartedOnTitleNumber = manifestations.filter(
    (manifestation) =>
      !manifestationsPartedOnTitleNumber.find((manifestationParted) => manifestationParted.id === manifestation.id)
  );

  if (isAtLeastOne(manifestationsPartedOnTitleNumber))
    return {
      partedManifestations: manifestationsPartedOnTitleNumber,
      wholeManifestations: manifestationsNotPartedOnTitleNumber,
    };

  const manifestationsPartedOnSeriesNumber: SortableManifestationWithPartNumber[] = manifestations
    .map((manifestation) => {
      const expression = work.expressions?.find((expression) =>
        [...(expression.manifestations ?? []), ...(expression.aggregateManifestations ?? [])].find(
          (expressionManifestation) => expressionManifestation.id === manifestation.id
        )
      );
      return { manifestation, expression, partNumber: expression?.collections?.[0]?.numberInSeries };
    })
    .filter(
      (
        manifestationWithPartNumber
      ): manifestationWithPartNumber is {
        manifestation: SortableManifestation;
        expression: Expression;
        partNumber: string;
      } =>
        //Inkluderer kun manifestasjonene med oppdelte delnummer (f.eks. del "1.1" og del "1.2", fordi del "1" er den fullstendige manifestasjonen)
        !!manifestationWithPartNumber.partNumber && manifestationWithPartNumber.partNumber.split(".").length > 1
    )
    .sort((a, b) => {
      return Number.parseFloat(a.partNumber ?? "") - Number.parseFloat(b.partNumber ?? "");
    })
    .map((manifestationWithPartNumber) => ({
      ...manifestationWithPartNumber.manifestation,
      partNumber: manifestationWithPartNumber.partNumber,
    }));

  const manifestationsNotPartedOnSeriesNumber = manifestations.filter(
    (manifestation) =>
      !manifestationsPartedOnSeriesNumber.find((manifestationParted) => manifestationParted.id === manifestation.id)
  );

  if (isAtLeastOne(manifestationsPartedOnSeriesNumber))
    return {
      partedManifestations: manifestationsPartedOnSeriesNumber,
      wholeManifestations: manifestationsNotPartedOnSeriesNumber,
    };

  return undefined;
};
