import { Work } from "@biblioteksentralen/cordata";
import { group, replace, sift, unique } from "radash";
import { LibraryInfo } from "../sanityQueries";
import { isStandardPublicationHoldings, useHoldingsData } from "./useHoldingsData";
import {
  countHoldingItems,
  getIsAvailable,
  getIsForUseInLibrary,
  getIsLoanable,
  getIsNotLost,
  getIsUnavailable,
} from "./useHoldingCountsForManifestations";

type LocationData = {
  availableForLoan: number;
  totalForLoan: number;
  totalNotForLoan: number;
  totalUnavailable: number;
  section: string;
  shelfLocation: string;
};

type Locations = Record<string, LocationData[] | undefined>;

const concatenateLocations = (locations: LocationData[]): LocationData[] =>
  locations.reduce((acc: LocationData[], locationData) => {
    const hasSameLocation = ({ shelfLocation, section }: LocationData) =>
      shelfLocation === locationData.shelfLocation && section === locationData.section;

    const existingData = acc.find(hasSameLocation);

    if (existingData) {
      const updatedData = {
        ...existingData,
        totalForLoan: existingData.totalForLoan + locationData.totalForLoan,
        availableForLoan: existingData.availableForLoan + locationData.availableForLoan,
      };
      return replace(acc, updatedData, hasSameLocation);
    }

    return [...acc, locationData];
  }, []);

// TODO Attempt some sorting on libraries?
export const useHoldingLocations = (
  work: Work,
  libraries: LibraryInfo[],
  manifestationIds?: string[]
): { locations: Locations; isLoading: boolean; hasError?: boolean } => {
  const { holdingsData, isLoading } = useHoldingsData(work, manifestationIds);

  const unGroupedLocationData = concatenateLocations(
    sift(
      Object.values(holdingsData ?? {}).map((holding) =>
        isStandardPublicationHoldings(holding) && !!holding.locations.length ? holding : undefined
      )
    ).flatMap(({ locations }) =>
      locations.map(({ location, items, shelfmark }) => ({
        section: unique(sift([location.branchName, location.path[0]?.name])).join(": "),
        shelfLocation: sift([...location.path.slice(1).map(({ name }) => name), shelfmark]).join(" - "),
        items,
        totalForLoan: countHoldingItems([{ items }], getIsNotLost, getIsLoanable),
        availableForLoan: countHoldingItems([{ items }], getIsLoanable, getIsAvailable),
        totalNotForLoan: countHoldingItems([{ items }], getIsForUseInLibrary),
        totalUnavailable: countHoldingItems([{ items }], getIsUnavailable),
      }))
    )
  );

  const locations = group(unGroupedLocationData, ({ section }) => section);

  return { isLoading, locations };
};
