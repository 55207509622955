import { Box, BoxProps, Button, HStack, Input } from "@biblioteksentralen/react";
import { PatronListInsertItem } from "@libry-content/redia-platform";
import { FormEventHandler, useState } from "react";
import { z } from "zod";
import { useRediaPlatformContext } from "../../../rediaPlatform/RediaPlatformProvider";
import { Translate, useTranslation } from "../../../utils/hooks/useTranslation";

type Props = {
  autoAddItem?: PatronListInsertItem;
  mutate: () => void;
  onClose: () => void;
  inputFocusRef?: React.RefObject<HTMLElement>;
} & BoxProps;

export const favoriteNameInputSchema = (t: Translate["t"]) =>
  z.object({
    name: z.string().min(1, t("Må være mellom 1 og 30 tegn")).max(30, t("Må være mellom 1 og 30 tegn")),
  });

export type FavoriteNameInputSchema = z.infer<ReturnType<typeof favoriteNameInputSchema>>;

const CreateFavoriteInput = ({ mutate, autoAddItem, onClose, inputFocusRef, ...boxProps }: Props) => {
  const { t } = useTranslation();
  const { rediaPlatform } = useRediaPlatformContext();
  const [validation, setValidation] = useState<z.ZodError<FavoriteNameInputSchema> | undefined>();
  const [favoriteName, setFavoriteName] = useState("");
  const [loading, setLoading] = useState(false);

  const handleCreatelist: FormEventHandler = async (event) => {
    event.preventDefault();
    setLoading(true);
    if (!rediaPlatform) {
      throw new Error("Redia Platform not initialized.");
    }
    const validation = favoriteNameInputSchema(t).safeParse({ name: favoriteName });
    if (!validation.success) {
      setValidation(validation.error);
      setLoading(false);
      return;
    }

    try {
      const { createdListId } = await rediaPlatform.createList(favoriteName);
      if (autoAddItem && createdListId) {
        await rediaPlatform.addItemToList(createdListId, autoAddItem);
      }
      mutate();
      setFavoriteName("");
      setLoading(false);
      setValidation(undefined);
      onClose?.();
    } catch (e) {
      setLoading(false);
      console.error(e);
    }
  };

  return (
    <Box as="form" onSubmit={handleCreatelist} {...boxProps}>
      <HStack justify="center" alignItems="start" gap=".5rem">
        <Input
          name="favorite"
          label={t("Opprett liste")}
          autoComplete="current-favorite"
          labelProps={{ fontWeight: 600 }}
          onChange={(e) => setFavoriteName(e.target.value)}
          errorMessage={validation?.formErrors.fieldErrors.name?.join(", ")}
          autoFocus
          value={favoriteName}
          ref={inputFocusRef}
        />
        <Button type="submit" variant="secondary" isLoading={loading} marginTop="2rem">
          {t("Opprett")}
        </Button>
      </HStack>
    </Box>
  );
};

export default CreateFavoriteInput;
