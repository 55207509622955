import { getCanonicalLanguageCode } from "@libry-content/localization";
import { Publication } from "@libry-content/types";
import { useTranslation } from "./useTranslation";

const norwegianLanguageCodes = ["no", "nb", "nn"];

/**
 * Language code "no" is equivalent with both bokmål and nynorsk
 */
export const languageCodesEquivalent = (languageCode1: string, languageCode2: string): boolean => {
  const canonicalLanguageCode1 = getCanonicalLanguageCode(languageCode1);
  const canonicalLanguageCode2 = getCanonicalLanguageCode(languageCode2);

  if (canonicalLanguageCode1 === "no" || canonicalLanguageCode2 === "no") {
    return (
      !!canonicalLanguageCode1 &&
      !!canonicalLanguageCode2 &&
      norwegianLanguageCodes.includes(canonicalLanguageCode1) &&
      norwegianLanguageCodes.includes(canonicalLanguageCode2)
    );
  }

  return canonicalLanguageCode1 === canonicalLanguageCode2;
};

/**
 * Indicate publication language if it's different than the current language
 */
export const usePublicationLangAttribute = (publication?: Publication): string | undefined => {
  const { lang } = useTranslation();
  const publicationLanguageCode = getCanonicalLanguageCode(publication?.language);
  const showPublicationLanguage = !!publicationLanguageCode && !languageCodesEquivalent(publicationLanguageCode, lang);

  return showPublicationLanguage ? publicationLanguageCode : undefined;
};
