import { Work } from "@biblioteksentralen/cordata";
import { Box, colors, HStack, SkeletonText, StackProps, Text, TextProps, VStack } from "@biblioteksentralen/react";
import { TranslateMicroText, useTranslation } from "../../utils/hooks/useTranslation";
import { HoldingCounts, useHoldingCountsForManifestations } from "../hooks/useHoldingCountsForManifestations";
import { ManifestationStatusForUser } from "./manifestations/useManifestationStatusForUser";
import { ExternalContentService } from "./reservation/externalContentService";

type HoldingTextProps = {
  textProps?: TextProps;
  separateLines?: boolean;
  work: Work;
  manifestationIds: string[];
} & StackProps;

const getDotColor = (holdingCounts: HoldingCounts) => {
  if (holdingCounts.reservableItems === 0 && (holdingCounts.forQuickLoan > 0 || holdingCounts.forUseInLibrary > 0)) {
    return colors.statusYellow;
  }

  if (holdingCounts.availableForLoan === 0 && holdingCounts.reservableItems > 0) {
    return colors.statusYellow;
  }

  if (holdingCounts.reservableItems === 0 && holdingCounts.forQuickLoan === 0 && holdingCounts.forUseInLibrary === 0) {
    return colors.statusRed;
  }

  return colors.statusGreen;
};

export const HoldingText = ({ textProps, separateLines, work, manifestationIds, ...stackProps }: HoldingTextProps) => {
  const { t } = useTranslation();
  const { holdingCounts, error } = useHoldingCountsForManifestations(work, manifestationIds);

  if (error) {
    console.error(error);
    return null;
  }

  if (!holdingCounts) {
    return <SkeletonText noOfLines={1} skeletonHeight="1rem" marginBottom="1rem" />;
  }

  const holdingTextFields = getHoldingTextFields(t, holdingCounts);

  return (
    <VStack spacing="0" alignItems="flex-start" style={{ hyphens: "none" }} {...stackProps}>
      <HStack spacing="0.5rem">
        {!separateLines && (
          <Box minWidth="0.5rem" minHeight="0.5rem" borderRadius="50%" background={getDotColor(holdingCounts)} />
        )}
        {separateLines ? (
          <VStack spacing="0">
            {holdingTextFields.map((text, idx) => (
              <Text key={idx} fontWeight="semibold" fontSize="sm" {...textProps}>
                {text}
                {holdingTextFields.length > 1 && "."}
              </Text>
            ))}
          </VStack>
        ) : (
          <Text fontWeight="semibold" fontSize="sm" {...textProps}>
            {holdingTextFields.join(". ")}
            {holdingTextFields.length > 1 && "."}
          </Text>
        )}
      </HStack>
    </VStack>
  );
};

const getPluralizer =
  (pluralStrings: [singular: string, plural: string][], count: number) =>
  (text: string): string =>
    count === 1 ? text : pluralStrings.reduce((result, [singular, plural]) => result.replace(singular, plural), text);

const getHoldingTextFields = (t: TranslateMicroText, holdingCounts: HoldingCounts): string[] => {
  const pluralize = getPluralizer([["ledig", "ledige"]], holdingCounts.itemCount);
  return getSingularHoldingTextFields(t, holdingCounts).map(pluralize);
};

/**
 * Display-logic for holding is defined here, should be kept in sync: https://bs-redia.atlassian.net/l/cp/zLEzuoAe
 */
const getSingularHoldingTextFields = (t: TranslateMicroText, holdingCounts: HoldingCounts): string[] => {
  const {
    itemCount: all,
    availableForReservation,
    availableForLoan,
    reservableItems: canBeReserved,
    numberOfReservations,
    availableForQuickLoan,
    forQuickLoan,
    forUseInLibrary,
    onOrder,
  } = holdingCounts;

  if (onOrder > 0 && onOrder === all) {
    // Item(s) are on order and can be reserved, but no items are available or loanable yet
    return [
      t("{antallBestilt} på vei til biblioteket, {antallVenteliste} på venteliste", {
        antallBestilt: onOrder,
        antallVenteliste: numberOfReservations,
      }),
    ];
  }

  // Items available both in the library and for reservation without queue
  if (availableForReservation > 0) {
    return [
      t("{antallTilgjengelig} av {totalt} ledig", {
        antallTilgjengelig: availableForReservation,
        totalt: all,
      }),
    ];
  }

  // Quick loan items available, can be reserved with queue
  if (availableForQuickLoan > 0 && canBeReserved > 0) {
    return [
      t("{antallTilgjengelig} av {totalt} ledig, {antallVenteListe} på venteliste", {
        antallTilgjengelig: 0,
        totalt: all - availableForQuickLoan,
        antallVenteListe: numberOfReservations,
      }),
      t("{antallTilgjengelig} av {totalt} ledig til hurtiglån", {
        antallTilgjengelig: availableForQuickLoan,
        totalt: forQuickLoan,
      }),
    ];
  }

  // No items available, can be reserved with queue
  if (availableForLoan === 0 && canBeReserved > 0) {
    return [
      t("{antallTilgjengelig} av {totalt} ledig, {antallVenteListe} på venteliste", {
        antallTilgjengelig: 0,
        totalt: all,
        antallVenteListe: numberOfReservations,
      }),
    ];
  }

  // Only have quick loan items
  if (forQuickLoan > 0 && canBeReserved === 0) {
    return [
      t("{antallTilgjengelig} av {totalt} ledig til hurtiglån", {
        antallTilgjengelig: availableForQuickLoan,
        totalt: forQuickLoan,
      }),
      t("Ikke mulig å reservere"),
    ];
  }

  // Only have items that can't be reserved, for other reasons than being quick loan items
  if (forUseInLibrary > 0 && canBeReserved === 0) {
    return [t("Ikke til hjemlån")];
  }

  console.error(`Unhandled holding text case: ${JSON.stringify(holdingCounts)}`);
  return [];
};

export const showHoldingText = (
  manifestationStatusForUser: ManifestationStatusForUser,
  currentExternalContentService?: ExternalContentService
) =>
  !currentExternalContentService &&
  !manifestationStatusForUser.isLoanedByUser &&
  !manifestationStatusForUser.isReservedByUser;
