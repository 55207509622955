import { Work } from "@biblioteksentralen/cordata";
import { StandardPublicationHoldingItem, StandardPublicationHoldingLocation } from "@libry-content/redia-platform";
import { sift, unique } from "radash";
import { useHoldingsData } from "./useHoldingsData";
import { useManifestationIdsForWork } from "./useManifestationIdsForWork";

/**
 * Summary of holding state combinations (from https://bs-redia.atlassian.net/l/cp/GvJidqrC)
 *
 * Description                                      | availableState | loanableState | reserveId
 * -------------------------------------------------------------------------------------------------
 * Available both for reservation and loan          |  "available"   |  "loanable"   |   [string]
 * Available for loan only, e.g. quick loan         |  "available"   |  "loanable"   |     null
 * -- should not occur --                           |  "available"   | "notLoanable" |   [string]
 * Available for use in library only                |  "available"   | "notLoanable" |     null
 * Available for reservation (with queue), not loan | "notAvailable" |  "loanable"   |   [string]
 * Quick loan but not currently available           | "notAvailable" |  "loanable"   |     null
 * On order / Kulturfond not yet in library         | "notAvailable" | "notLoanable" |   [string]
 * Lost etc                                         | "notAvailable" | "notLoanable" |     null
 *
 */

export const getIsAvailable = ({ availableState }: StandardPublicationHoldingItem) => availableState === "available";
export const getIsLoanable = ({ loanableState }: StandardPublicationHoldingItem) => loanableState === "loanable";
export const getIsNotLoanable = ({ loanableState }: StandardPublicationHoldingItem) => loanableState !== "loanable";

const getIsReservable = ({ reserveId }: StandardPublicationHoldingItem) => !!reserveId;
const getIsForQuickLoan = (item: StandardPublicationHoldingItem) => !getIsReservable(item) && getIsLoanable(item);

export const getIsForUseInLibrary = (item: StandardPublicationHoldingItem) =>
  !getIsReservable(item) && !getIsLoanable(item) && getIsAvailable(item);

export const getIsUnavailable = (item: StandardPublicationHoldingItem) =>
  !getIsReservable(item) && !getIsLoanable(item) && !getIsAvailable(item);

export const getIsNotLost = (item: StandardPublicationHoldingItem) =>
  getIsAvailable(item) || getIsLoanable(item) || getIsReservable(item);

export const getIsOnOrder = (item: StandardPublicationHoldingItem) =>
  !getIsAvailable(item) && !getIsLoanable(item) && getIsReservable(item);

export const countHoldingItems = (
  locations: Pick<StandardPublicationHoldingLocation, "items">[],
  ...conditions: ((item: StandardPublicationHoldingItem) => boolean)[]
) =>
  locations.flatMap(({ items = [] }) => items.filter((item) => conditions.every((condition) => condition(item))))
    .length;

export type HoldingCounts = {
  itemCount: number;
  reservableItems: number;
  forQuickLoan: number;
  forUseInLibrary: number;
  availableForLoan: number;
  availableForReservation: number;
  availableForQuickLoan: number;
  numberOfReservations: number | null;
  onOrder: number;
};

const addCounts = (leftCount: number | null | undefined, rightCount: number | null | undefined) =>
  leftCount === null || rightCount === null ? null : (leftCount ?? 0) + (rightCount ?? 0);

const concatenateHoldingCounts = (holdings: HoldingCounts[]) =>
  holdings.reduce(
    (concatenated, holdingCount) =>
      unique([...Object.keys(concatenated), ...Object.keys(holdingCount)]).reduce((acc, key) => {
        return { ...acc, [key]: addCounts((concatenated as any)[key], (holdingCount as any)[key]) };
      }, {} as HoldingCounts),
    {} as HoldingCounts
  );

type UseHoldingCountsForManifestations = (
  work: Work,
  manifestationIds: string[]
) => {
  holdingCounts: HoldingCounts | undefined;
  isLoading: boolean;
  error?: string;
};

/**
 * Given a work and a related set of manifestations, count total and available holdings for different usage restrictions.
 */
export const useHoldingCountsForManifestations: UseHoldingCountsForManifestations = (work, manifestationIds) => {
  const { holdingsData, isLoading } = useHoldingsData(work, manifestationIds);

  if (!holdingsData) return { isLoading, holdingCounts: undefined, reservableIds: [] };

  // Note that the data for each holding is array, for the special case of periodicals with multiple issues.
  // For now we use the first item `holding` only
  const holdingCounts = concatenateHoldingCounts(
    sift(Object.values(holdingsData ?? {})).map(({ locations, numberOfReservations }) => ({
      itemCount: countHoldingItems(locations, getIsNotLost),
      reservableItems: countHoldingItems(locations, getIsReservable),
      forQuickLoan: countHoldingItems(locations, getIsForQuickLoan),
      forUseInLibrary: countHoldingItems(locations, getIsForUseInLibrary),
      availableForLoan: countHoldingItems(locations, getIsLoanable, getIsAvailable),
      availableForReservation: countHoldingItems(locations, getIsReservable, getIsAvailable),
      availableForQuickLoan: countHoldingItems(locations, getIsForQuickLoan, getIsAvailable),
      numberOfReservations: numberOfReservations ?? 0,
      //onOrder is the number of holdings ordered by library, but not yet received from supplier
      onOrder: countHoldingItems(locations, getIsOnOrder),
    }))
  );

  return { holdingCounts, isLoading };
};

export const useHoldingCountsForWork = (work: Work) => {
  const manifestationIds = useManifestationIdsForWork(work);
  const holdingCounts = useHoldingCountsForManifestations(work, manifestationIds);
  return holdingCounts;
};
