import { AgeGroup, Work } from "@biblioteksentralen/cordata";
import { Modify } from "@biblioteksentralen/types";
import { sort, unique } from "radash";
import { TranslateMicroText } from "../../utils/hooks/useTranslation";
import { TranslateSanityContent } from "@libry-content/localization";

const isAgeGroup = (item: unknown): item is AgeGroup =>
  typeof item === "object" && (item as any)?.["type"] === "AgeGroup";

type AgeSpan = [number, number];

const ageGroupCodes = ["a", "b", "bu", "u", "mu"] as const;

type AgeGroupCode = (typeof ageGroupCodes)[number];

const ageGroupYearsMap: Record<AgeGroupCode, AgeSpan> = {
  a: [3, 5],
  b: [6, 8],
  bu: [9, 10],
  u: [11, 12],
  mu: [13, 15],
};

// TODO: Can probably build this into the cordata type AgeGroup?
const hasAgeGroupCode = (ageGroup: AgeGroup): ageGroup is Modify<AgeGroup, { code: AgeGroupCode }> =>
  (ageGroupCodes as readonly string[]).includes(ageGroup.code);

const concatenateAgeGroups = (ageGroups: AgeGroup[]): (AgeGroup | AgeSpan)[] => {
  const withAgeGroupCode = ageGroups.filter(hasAgeGroupCode);
  const withoutAgeGroupCode = ageGroups.filter((ageGroup) => !hasAgeGroupCode(ageGroup));

  const sortedAgeSpans = sort(
    withAgeGroupCode.map(({ code }) => ageGroupYearsMap[code]!),
    ([from]) => from
  );

  const concatendatedAgeGroupsWithCode = sortedAgeSpans.reduce((acc, ageSpan) => {
    const previousAgeSpan = acc.slice(-1)[0];

    if (previousAgeSpan && ageSpan[0] - previousAgeSpan[1] === 1) {
      return [...acc.slice(0, acc.length - 1), [previousAgeSpan[0], ageSpan[1]] as AgeSpan];
    }

    return [...acc, ageSpan];
  }, [] as AgeSpan[]);

  return [...concatendatedAgeGroupsWithCode, ...withoutAgeGroupCode];
};

export const getAgeGroups = (t: TranslateMicroText, ts: TranslateSanityContent, { ageGroups }: Work) =>
  unique(
    concatenateAgeGroups(ageGroups).map((ageGroupOrSpan) =>
      isAgeGroup(ageGroupOrSpan) ? ts(ageGroupOrSpan.name) : `${ageGroupOrSpan[0]} – ${ageGroupOrSpan[1]} ${t("år")}`
    )
  );
