import { Work } from "@biblioteksentralen/cordata";
import { isAtLeastOne } from "@biblioteksentralen/types";
import { RefObject } from "react";
import ErrorBoundary from "../../../components/errorPages/ErrorBoundary";
import { ModalContainer } from "../../../components/ModalContainer";
import PopoverContainer from "../../../components/PopoverContainer";
import { LoginForm } from "../../../rediaPlatform/LoginForm";
import { useRediaPlatformContext } from "../../../rediaPlatform/RediaPlatformProvider";
import { useTranslation } from "../../../utils/hooks/useTranslation";
import { getRelevantManifestations } from "../../cordata/manifestations";
import { SortableManifestation } from "../../types";
import { useCurrentVariantCharacteristics } from "../works/WorkViewBanner/useCurrentVariantCharacteristics";
import FavoritePopoverBody from "./FavoritePopoverBody";
import { getFilteredWork } from "../../searchApi/searchWorks";
import { getSearchConfig } from "../../searchApi/searchConfig";
import { useCommonData } from "../../../components/layout/CommonDataProvider";
import useSWR from "swr";
import { Alert, Spinner } from "@biblioteksentralen/react";
import { isWork } from "../../cordata/works";

type PatronListModalProps = {
  isOpen: boolean;
  onClose: () => void;
  workOrWorkId: Work | string;
  onCloseFocusRef: RefObject<HTMLDivElement>;
  popoverTrigger: React.ReactNode;
};

export const FavoritePopover = ({
  isOpen,
  onClose,
  workOrWorkId,
  onCloseFocusRef,
  popoverTrigger,
}: PatronListModalProps) => {
  const { t } = useTranslation();
  const { user, isSessionReady } = useRediaPlatformContext();

  const headingTitle = t("Legg i mine lister");

  const handleClose = () => {
    onClose();
  };

  if (isSessionReady && !user) {
    return (
      <>
        {popoverTrigger}
        <ModalContainer heading={headingTitle} isOpen={isOpen} onClose={handleClose} finalFocusRef={onCloseFocusRef}>
          <LoginForm />
        </ModalContainer>
      </>
    );
  }

  return (
    <PopoverContainer
      popoverTrigger={popoverTrigger}
      heading={headingTitle}
      isOpen={isOpen}
      onClose={handleClose}
      isLazy // Bruker isLazy for lazy loading for å unngå at FavoritePopoverBody lastes inn før den er synlig.
    >
      {isWork(workOrWorkId) ? (
        <FavoritePopoverWrapper work={workOrWorkId} onClose={onClose} />
      ) : (
        <WorkIdToWorkWrapper workId={workOrWorkId} onClose={onClose} />
      )}
    </PopoverContainer>
  );
};

const WorkIdToWorkWrapper = ({ workId, onClose }: { workId: string; onClose: () => void }) => {
  const { t } = useTranslation();
  const site = useCommonData().site;
  const searchConfig = getSearchConfig(site);

  const { data: work, error } = useSWR([workId, searchConfig], async () => {
    return await getFilteredWork(workId, searchConfig);
  });

  if (error)
    return <Alert status="warning">{t("Beklager, det er ikke mulig å legge til denne i listen akkurat nå.")}</Alert>;

  if (!work) return <Spinner />;

  return <FavoritePopoverWrapper work={work} onClose={onClose} />;
};

// Lazy loading wrapper
const FavoritePopoverWrapper = ({ work, onClose }: { work: Work; onClose: () => void }) => {
  const { currentLanguagesList, currentDocumentType } = useCurrentVariantCharacteristics(work);
  const manifestations = getRelevantManifestations(work, currentLanguagesList, currentDocumentType);

  return (
    <ErrorBoundary boundaryName="FavoritePopoverWrapper">
      {isAtLeastOne<SortableManifestation>(manifestations) && (
        <FavoritePopoverBody manifestations={manifestations} work={work} onClose={onClose} />
      )}
    </ErrorBoundary>
  );
};
