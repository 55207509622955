import { DocumentType } from "@biblioteksentralen/cordata";
import { Box, BoxProps, Text } from "@biblioteksentralen/react";
import InternalLink from "../../../components/InternalLink";
import { useTranslation } from "../../../utils/hooks/useTranslation";
import { getDocumentTypeDefiniteNoun } from "../../cordata/documentTypes";
import type { ManifestationStatusForUser as ManifestationStatusForUserType } from "./useManifestationStatusForUser";

type ManifestationStatusForUserProps = BoxProps & {
  manifestationStatusForUser: ManifestationStatusForUserType;
  documentType: DocumentType | undefined;
};

export const ManifestationStatusForUser = ({
  manifestationStatusForUser,
  documentType,
  ...boxProps
}: ManifestationStatusForUserProps) => {
  const { t, ts } = useTranslation();
  const { isLoanedByUser, isReservedByUser, error } = manifestationStatusForUser;

  if (error) return null;

  if (isLoanedByUser) {
    return (
      <Box textAlign="left" margin="1rem auto 0 auto" {...boxProps}>
        {t("Du har lånt")} {ts(getDocumentTypeDefiniteNoun(documentType, { withDeterminative: true }))}
        {"."}
        <InternalLink display="block" marginBottom="0.75rem" href="/min-side/laan">
          {t("Se mine lån")}
        </InternalLink>
      </Box>
    );
  }

  if (isReservedByUser) {
    return (
      <Box textAlign="left" margin="1rem auto 0 auto" {...boxProps}>
        <Text>
          {t("Du har reservert")} {ts(getDocumentTypeDefiniteNoun(documentType, { withDeterminative: true }))}
          {"."}
        </Text>
        <InternalLink display="block" marginBottom="0.75rem" href="/min-side/reservasjon">
          {t("Se mine reservasjoner")}
        </InternalLink>
      </Box>
    );
  }

  return null;
};
