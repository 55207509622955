import { Role, roles } from "@biblioteksentralen/cordata";
import { FrontendLocale, LocalizedField } from "@libry-content/localization";
import { Translate } from "../../utils/hooks/useTranslation";

export type ContributorRoleCode = NonNullable<Role["marc21Code"]>;

const contributorRoleCodeOrder: ContributorRoleCode[] = [
  "aut", // forfatter
  "art", // kunstner
  "trl", // oversetter
  "drt", // regissør
  "aus", // manusforfatter
];

/**
 * We use marc21-codes for roles, which do not differentiate between "bearbeider" and "forfatter".
 * Since the latter is usually more appropriate we ignore the former.
 */
const rolesWithoutBearb = roles.filter(({ code }) => code !== "bearb.");

export const getContributorRoleLabel = (languageCode: FrontendLocale, roleCode: ContributorRoleCode) =>
  rolesWithoutBearb.find(({ marc21Code }) => marc21Code === roleCode)?.label?.[languageCode];

export const getContributorRoleSortIndex = (roleCode: ContributorRoleCode) =>
  (contributorRoleCodeOrder as string[]).includes(roleCode)
    ? (contributorRoleCodeOrder as string[]).indexOf(roleCode)
    : contributorRoleCodeOrder.length;

const roleLabelTransformations: Partial<Record<ContributorRoleCode, LocalizedField<string>>> = {
  art: { nb: "illustrasjoner av", nn: "illustrasjonar av" },
  nrt: { nb: "innlest av", nn: "lese inn av " },
  aus: { nb: "manus", nn: "manus" },
  ant: { nb: "originalforfatter", nn: "originalforfattar" },
  trl: { nb: "oversatt av", nn: "omsett av" },
  drt: { nb: "regi", nn: "regi" },
  act: { nb: "skuespillere", nn: "skodespelare" },
};

/** Some agents generated from library catalogue records are missing roles. */
export const getTransformedRoleLabel = (
  languageCode: FrontendLocale,
  roleCode: ContributorRoleCode | undefined,
  t: Translate["t"]
) =>
  (roleCode &&
    (roleLabelTransformations[roleCode]?.[languageCode] || getContributorRoleLabel(languageCode, roleCode))) ||
  t("Bidragsyter");
