import useSWR from "swr";
import { swrUserNamespace, useRediaPlatformContext } from "../../../rediaPlatform/RediaPlatformProvider";
import { tryFetchPublications } from "./tryFetchPublications";
import { BranchInformation, PatronReservation } from "@libry-content/redia-platform";
import { MinsidePublication, minsidePublicationMap } from "./minsidePublicationMap";

export interface ReservationWithPublicationMetadata {
  reservation: PatronReservation;
  publication: MinsidePublication;
  branch?: BranchInformation;
}

export const useReservations = () => {
  const { user, rediaPlatform } = useRediaPlatformContext();
  const swrCacheKey = user && rediaPlatform ? [swrUserNamespace, user?.identifiers?.[0]?.value, "reservations"] : null;

  return useSWR(swrCacheKey, async () => {
    if (!rediaPlatform) {
      throw new Error("Redia Platform should be initialized at this point.");
    }

    const reservations = (await rediaPlatform.getReservations()) ?? [];
    const publicationResponse = await tryFetchPublications(rediaPlatform, reservations);
    const branches = (await rediaPlatform?.getBranches()) ?? [];

    return reservations.map(
      (reservation): ReservationWithPublicationMetadata => ({
        reservation,
        publication: minsidePublicationMap({
          id: reservation.id,
          publicationId: reservation.publicationId,
          publication: publicationResponse[reservation.publicationId],
          patronPublicationItem: reservation,
        }),
        branch: branches[reservation.pickup.location.branch.code],
      })
    );
  });
};
