import { Box, BoxProps, Text, TextProps } from "@biblioteksentralen/react";
import { LocalizedField, isLocalizedField } from "@libry-content/localization";
import { ComponentProps, Fragment } from "react";
import InternalLink from "../../components/InternalLink";
import { useTranslation } from "../../utils/hooks/useTranslation";

type TruncatedCommaListProps = {
  items: { name: string | LocalizedField<string>; href?: string }[] | undefined;
  max: number | undefined;
  containerProps?: BoxProps;
  linkProps?: Partial<ComponentProps<typeof InternalLink>>;
  separator?: string;
  lineBreak?: boolean;
  seperatorSpacing?: boolean;
  label?: string;
} & TextProps;

export const TruncatedList = ({
  max,
  items,
  containerProps,
  linkProps,
  label,
  separator = ", ",
  lineBreak = false,
  seperatorSpacing = false,
  ...textProps
}: TruncatedCommaListProps) => {
  const { ts } = useTranslation();
  if (!items?.length) return null;

  const shownItems = items.slice(0, max);

  return (
    <Box {...containerProps}>
      <Text as="span" display="inline-block" {...textProps}>
        {shownItems.map(({ name, href }, idx) => {
          const nameString = `${isLocalizedField(name) ? ts(name) : name}`;
          const separatorNode =
            idx < shownItems.length - 1 ? (
              <Fragment key={`separator-${idx}`}>
                <span>{separator}</span>
                {lineBreak && <br />}
              </Fragment>
            ) : null;

          if (!nameString) return null;

          if (href) {
            return [
              <InternalLink
                marginInline={seperatorSpacing ? "0.2rem" : "0"}
                key={idx}
                href={href}
                aria-label={label ? `${label}: ${nameString}` : nameString}
                {...linkProps}
              >
                {nameString}
              </InternalLink>,
              separatorNode,
            ];
          }

          return [
            <Text as="span" key={idx}>
              {nameString}
            </Text>,
            separatorNode,
          ];
        })}
        {typeof max !== "undefined" && items.length > max && (
          <Text as="span" {...textProps} textDecoration="none">
            {" "}
            (+{items.length - max})
          </Text>
        )}
      </Text>
    </Box>
  );
};
