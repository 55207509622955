import { Contributor } from "@biblioteksentralen/cordata";
import { Box, Flex, TableCellProps, TableRowProps, Td, Th, Tr, colors } from "@biblioteksentralen/react";
import { group, sort } from "radash";
import { ReactNode } from "react";
import { useTranslation } from "../../../utils/hooks/useTranslation";
import { addAgentHrefs, orderByMainContributor } from "../../cordata/contributors";
import { getContributorRoleSortIndex, getTransformedRoleLabel } from "../../cordata/roles";
import { TruncatedList } from "../TruncatedList";

// TODO: Handle several roles
const groupContributors = (contributors: Contributor[]) =>
  group(orderByMainContributor(contributors), (contributor) => contributor.roles[0]?.marc21Code ?? "ukjent");

export const ManifestationViewContributorRows = ({ contributors }: { contributors: Contributor[] }) => {
  const { lang, t } = useTranslation();
  const groupeContributors = groupContributors(contributors);
  const sortedContributors = sort(Object.entries(groupeContributors), ([roleCode]) =>
    getContributorRoleSortIndex(roleCode)
  );

  return (
    <>
      {sortedContributors.map(([roleCode, roleContributors]) => {
        const agents = addAgentHrefs(orderByMainContributor(roleContributors ?? []))?.map(({ agent }) => agent);

        return (
          <TableRow key={roleCode}>
            <TableCellTitle>{getTransformedRoleLabel(lang, roleCode, t)}</TableCellTitle>
            <TableCellValue>
              <Flex justifyContent="flex-end">
                <TruncatedList items={agents} max={undefined} lineBreak separator="" lineHeight="1.3" />
              </Flex>
            </TableCellValue>
          </TableRow>
        );
      })}
    </>
  );
};

export const FlexRow = ({ title, value }: { title?: string; value: string | ReactNode }) => {
  return (
    <Flex justifyContent="space-between" flexDir={{ base: "column", sm: "row" }}>
      <Box color={colors.grey60} fontSize="lg" _firstLetter={{ textTransform: "uppercase" }} flexShrink="0">
        {title}
      </Box>
      <Box fontSize="lg" textAlign={{ base: "left", sm: "right" }}>
        {value}
      </Box>
    </Flex>
  );
};

export const TableRow = (props: TableRowProps) => (
  <Tr
    borderBottomWidth="2px"
    _last={{ borderBottom: "none" }}
    display="flex"
    justifyContent="space-between"
    {...props}
  />
);

export const TableCellTitle = (props: TableCellProps) => (
  <Th
    color={colors.grey60}
    textTransform="none"
    fontWeight="normal"
    fontSize="lg"
    letterSpacing="normal"
    lineHeight="1.3"
    _firstLetter={{ textTransform: "uppercase" }}
    style={{ hyphens: "none" }}
    padding="1rem 0.5rem"
    border="none"
    {...props}
  />
);

export const TableCellValue = (props: TableCellProps) => (
  <Td textAlign="end" padding="1rem 0.5rem" border="none" lineHeight="1.3" {...props} />
);
