import { Work, formatIsbdTitle } from "@biblioteksentralen/cordata";
import { HStack, Stack, Text } from "@biblioteksentralen/react";
import { useTranslation } from "../../../utils/hooks/useTranslation";
import { listAsText } from "../../../utils/listAsText";
import { formatAgentName } from "../../cordata/agents";
import { PickupLocation } from "../../../components/minside/dataFetchers/useLocations";
import { WorkCoverImage } from "../WorkCoverImage";
import { ReservableHolding } from "./useChosenReservableHolding";
import { HoldingCounts } from "../../hooks/useHoldingCountsForManifestations";

type ReservationModalSuccessProps = {
  work: Work;
  reservableHolding: ReservableHolding;
  selectedLibrary: PickupLocation;
  hideImage?: boolean;
  holdingCounts?: HoldingCounts;
};

export const ReservationModalSuccess = ({
  work,
  hideImage,
  selectedLibrary,
  reservableHolding: { manifestation },
  holdingCounts,
}: ReservationModalSuccessProps) => {
  const { t, ts } = useTranslation();
  const isAvailable = holdingCounts?.availableForLoan !== 0;
  const isOnWaitingList = holdingCounts?.numberOfReservations !== 0;
  const isBook = manifestation.documentType?.format === "Bok";

  return (
    <Stack as="section" spacing="2rem">
      <HStack spacing="1rem" alignItems="flex-start">
        <WorkCoverImage
          work={work}
          representativeManifestation={manifestation}
          usePlaceholder={hideImage}
          maxWidth="7rem"
        />
        <Stack spacing="0.75rem">
          <Text>
            {t("Du har reservert")} {"“"}
            {formatIsbdTitle(manifestation)}
            {"”"} {t("av")}{" "}
            {listAsText(
              work.contributors.map(({ agent }) => formatAgentName(ts, agent)),
              t("og")
            )}
            {"."}
          </Text>
          <Text>
            {t("Du får beskjed når reservasjonen er klar til henting på {libraryName}.", {
              libraryName: selectedLibrary.name,
            })}
          </Text>
        </Stack>
      </HStack>
      {!isAvailable && isBook && (
        <>
          {isOnWaitingList ? (
            <Text>
              {t("Du er nummer {kø} på ventelisten, vil du lese noe annet i mellomtiden?", {
                kø: holdingCounts.numberOfReservations,
              })}
            </Text>
          ) : (
            <Text display="inline-block">{t("Denne boken er ikke ledig, vil du lese noe annet i mellomtiden?")}</Text>
          )}
        </>
      )}
    </Stack>
  );
};
