import { DocumentTypeCode, Origin } from "@biblioteksentralen/cordata";

// Will eventually include other external services, like ebooks and other streaming services
export type ExternalContentService = {
  originName: Extract<Origin["name"], "filmoteket">;
  documentTypeCode: DocumentTypeCode;
  getPath: (id: string | undefined) => string | undefined;
};

//List of external content services currently used in our search
export const supportedExternalContentServices: ExternalContentService[] = [
  {
    originName: "filmoteket",
    documentTypeCode: "ea",
    getPath: (id: string | undefined) => id && `https://filmoteket.no/film/${id}`,
  },
] as const;

export const getExternalContentService = (origin: Origin | undefined): ExternalContentService | undefined => {
  if (!origin) return undefined;
  return supportedExternalContentServices.find(
    (externalContentService) => externalContentService.originName === origin.name
  );
};
