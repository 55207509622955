import { Manifestation } from "@biblioteksentralen/cordata";
import { useLoans } from "../../../components/minside/dataFetchers/useLoans";
import { useReservations } from "../../../components/minside/dataFetchers/useReservations";
import { useRediaPlatformContext } from "../../../rediaPlatform/RediaPlatformProvider";
import { Unauthenticated } from "@libry-content/redia-platform";

export type ManifestationStatusForUser = {
  error?: unknown;
  isLoading?: boolean;
  isLoanedByUser?: boolean;
  isReservedByUser?: boolean;
  loanedManifestations?: Manifestation[];
  reservedManifestations?: Manifestation[];
  isReady: boolean;
  onLoansUpdate?: () => void;
  onReservationsUpdate?: () => void;
};

export const useManifestationStatusForUser = (manifestations: Manifestation[]): ManifestationStatusForUser => {
  const { user, isSessionReady } = useRediaPlatformContext();
  const { data: loans, error: loansError, isLoading: loansIsLoading, mutate: onLoansUpdate } = useLoans();
  const {
    data: reservations,
    error: reservationsError,
    isLoading: reservationsIsLoading,
    mutate: onReservationsUpdate,
  } = useReservations();

  const loanLocalIds = loans?.flatMap(({ loan }) => loan.publicationId);
  const reservationLocalIds = reservations?.flatMap(({ reservation }) => reservation.publicationId);

  const loanedManifestations = manifestations.filter(({ libraryCatalogueRecords = [] }) =>
    libraryCatalogueRecords.some(({ localRecordId }) => !!localRecordId && loanLocalIds?.includes(localRecordId))
  );

  const reservedManifestations = manifestations.filter(({ libraryCatalogueRecords = [] }) =>
    libraryCatalogueRecords.some(({ localRecordId }) => !!localRecordId && reservationLocalIds?.includes(localRecordId))
  );

  const isLoading = loansIsLoading || reservationsIsLoading;
  const error: unknown = loansError || reservationsError;

  if (error) {
    if (!(error instanceof Unauthenticated)) {
      console.error(new Error("Failed to get manifestation status for user", { cause: error }));
    }
    return { error, isReady: true };
  }
  if (isSessionReady && !user) return { isReady: true };
  if (!loanLocalIds || !reservationLocalIds || !isSessionReady) return { isReady: false };

  return {
    isLoanedByUser: loanedManifestations.length > 0,
    isReservedByUser: reservedManifestations.length > 0,
    loanedManifestations,
    reservedManifestations,
    isLoading,
    onLoansUpdate,
    onReservationsUpdate,
    isReady: true,
  };
};
