import { CollectionExpressionEntry, formatIsbdTitle } from "@biblioteksentralen/cordata";
import { Box, colors, Text, TextProps, VStack } from "@biblioteksentralen/react";
import { unique } from "radash";
import { useTranslation } from "../../utils/hooks/useTranslation";
import {
  collectionExpressionEntryHasWork,
  collectionExpressionEntryIsNotFictionalUniverse,
} from "../cordata/collections";
import InternalLink from "../../components/InternalLink";
import { getPath } from "../../utils/getPath";

type CollectionsProps = TextProps & { collections: CollectionExpressionEntry[] | undefined };

export const Collections = ({ collections, ...textProps }: CollectionsProps) => {
  const { t } = useTranslation();
  const collectionsWithWork = collections
    ?.filter(collectionExpressionEntryHasWork)
    .filter(collectionExpressionEntryIsNotFictionalUniverse);

  if (!collectionsWithWork?.length) return null;
  const uniqueCollections = unique(collectionsWithWork, (entry) => entry.collection.workExpressed?.id);

  return (
    <VStack spacing="0" alignItems="flex-start">
      {uniqueCollections.map((collectionExpressionWithWork) => (
        <Box
          sx={{ WebkitBoxOrient: "vertical", WebkitLineClamp: 3 }}
          // overflow="hidden" // TODO: Er denne nødvendig? Fjernet for å fikse feil på fokusmarkering på lenke
          textOverflow="ellipsis"
          display="-webkit-box"
          maxWidth="100%"
          key={collectionExpressionWithWork.collection.workExpressed?.id}
          {...textProps}
        >
          <Text as="span" color={colors.grey60}>
            {t("Del {nummer} av", { nummer: collectionExpressionWithWork.numberInSeries ?? "" })}
          </Text>{" "}
          <InternalLink href={getPath(collectionExpressionWithWork.collection)}>
            {formatIsbdTitle(collectionExpressionWithWork.collection)}
          </InternalLink>
        </Box>
      ))}
    </VStack>
  );
};
